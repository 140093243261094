import { Vue, createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import clickOutside from './directives/clickOutside'
import Loader from './services/LoaderOverlay';
import { useJwt } from '@vueuse/integrations/useJwt';
// import main css
// import './css/main.css'
import '/index.css'
import store from './store'; 

axios.defaults.baseURL = process.env.VUE_APP_APIURL;

axios.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const { payload } = useJwt(token);
        const exp = payload.value.exp * 1000;
        // console.log(exp);
        // console.log(Date.now());
        if (Date.now() >= exp) {
            console.warn("Token expired, logging out...");
            handleLogout();
            return Promise.reject(new Error("Token expired"));
        }
      } catch (error) {
          console.error("Invalid token detected, logging out...");
          handleLogout();
          return Promise.reject(new Error("Invalid token"));
      }
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

axios.interceptors.response.use(response => {
  return response;
}, error => {
  console.log("Axios Response Interceptor triggered:");
  const appInstance = document.getElementById('app')?.__vue_app__;
  if (appInstance) {
    console.log("in app instance:");
    const errorModal = appInstance._instance?.refs?.errorModal;
    console.log(error.response);
    console.log("error status:" , error.response.status);
    if (error.response && error.response.data) {
      console.log("in error modal instance:");
      if (error.response.status == 401) {
        console.warn("401 detected, logging out...");
        // errorModal.show(error.response.data.message || 'Your session has expired. Please log in again.');
        // console.log('Outside close');
        // errorModal.onCloseCallback = () => {
        // Clear local storage and redirect after the modal is closed
        localStorage.removeItem('token');
        localStorage.removeItem('darkmode');
        localStorage.removeItem('user_id');
        localStorage.removeItem('internal');
        console.log("Redirecting to login page...");
        router.push({ name: 'login' }).then(() => {
          window.location.reload();
        });
        // };
      } else {
        errorModal.show(error.response.data.message || 'An error occurred.');
      }
    }
  }
  return Promise.reject(error);
});

function handleLogout() {
  localStorage.removeItem('token');
  localStorage.removeItem('darkmode');
  localStorage.removeItem('user_id');
  localStorage.removeItem('internal');

  router.push({ name: 'login' }).then(() => {
      window.location.reload();
  });
}

async function fetchLogo() {
  try {
      const response = await axios.get('/getlogo');
      const logoName = response.data.logoname || 'aim_logo.png';
      store.commit('setLogo', logoName);
      localStorage.setItem('logo', logoName);
  } catch (error) {
      console.error('Error fetching logo:', error);
      store.commit('setLogo', 'aim_logo.png'); 
      localStorage.setItem('logo', 'aim_logo.png');
  }
}

fetchLogo();

const app = createApp(App);
app.directive('click-outside', clickOutside);
app.use(store);
app.use(router);
app.use(Loader);
app.mount('#app');