<template>
  <div class="max-w-full mx-auto my-5 border rounded-2xl px-6 py-6 dark:bg-gray-600 dark:border-gray-600">
    <div class="bg-gray-100 dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
      <div class="overflow-x-auto">
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
            <th scope="col" class="px-4 py-3 cursor-pointer">ID
            </th>
            <th scope="col" class="px-4 py-3">Actions</th>
            <th scope="col" class="px-4 py-3">Details</th>
            <th scope="col" class="px-4 py-3 cursor-pointer">Submitted
            </th>
            <th scope="col" class="px-4 py-3 cursor-pointer">Priority
            </th>
            <th scope="col" class="px-4 py-3 cursor-pointer">Requested By
            </th>
            <th scope="col" class="px-4 py-3 cursor-pointer">Assigned To
            </th>
            <th scope="col" class="px-4 py-3 cursor-pointer" >Last Activity
            </th>
            <th scope="col" class="px-4 py-3 cursor-pointer">Hours
            </th>
            <th scope="col" class="px-4 py-3 cursor-pointer">Days Old
            </th>
          </tr>
          </thead>
          <tbody class="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
            <template v-if="ticket">
              <tr class="hover:bg-gray-100 dark:hover:bg-gray-700">
                <td class="px-4 py-2">
                  <span class="inline-flex items-center rounded-md text-gray-700 bg-gray-200 dark:bg-gray-100 px-2 py-1 text-xs font-medium ring-1 ring-inset ring-gray-500/10">{{ ticket.id }}</span>
                </td>
                <td class="px-4 py-2">
                  <div class="flex items-center justify-left space-x-2">
                    <button @click="startJob(ticket.id)" title="Start Job">
                      <svg class="h-5 w-5 text-red-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <polygon points="10 8 16 12 10 16 10 8" /></svg>
                    </button>
                    <button title="Mark as Complete" @click="openSetCompleteTicketModal(ticket)">
                      <svg class="h-5 w-5 text-green-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />  <polyline points="22 4 12 14.01 9 11.01" /></svg>
                    </button>
                    <button title="Transfer" @click="openTransferTicketModal(ticket)">
                      <svg class="h-5 w-5 text-blue-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="5" cy="18" r="2" />  <circle cx="19" cy="6" r="2" />  <path d="M19 8v5a5 5 0 0 1 -5 5h-3l3 -3m0 6l-3 -3" />  <path d="M5 16v-5a5 5 0 0 1 5 -5h3l-3 -3m0 6l3 -3" /></svg>
                    </button>
                    <button @click="editTicket(ticket.id)" title="Edit Ticket">
                      <svg class="h-5 w-5 text-yellow-500" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                        <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                        <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
                      </svg>
                    </button>
                  </div>
                </td>
                <td class="px-4 py-2 font-medium text-gray-900 dark:text-white">
                  <div>{{ ticket.redmineissueid != 0 ? ticket.redmineissueid : '' }}</div>
                  <div v-html="renderMarkdown(ticket.description)"></div>
                </td>
                <td class="px-4 py-2 whitespace-nowrap">{{ ticket.submitted }}</td>
                <td class="px-4 py-2 whitespace-nowrap">
                  <div class="flex items-center justify-left space-x-2">
                    <button title="Back a day" @click="adjustPriority(ticket, -1)">
                      <svg class="h-5 w-5 text-blue-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M18 15l-6-6l-6 6h12" transform="rotate(270 12 12)" /></svg>
                    </button>
                    <button  @click="togglePriorityOptions(ticket.id)" class="relative">{{ ticket.pdate }} {{ ticket.ptime }}
                      <div v-if="showPriorityOptions === ticket.id" class="absolute z-10 mt-1 bg-white shadow-lg w-48 rounded-md">
                        <ul class="text-gray-700">
                          <li class="px-4 py-2 hover:bg-gray-100 cursor-pointer" @click="setPriorityDate(ticket, 'today')">Today</li>
                          <li class="px-4 py-2 hover:bg-gray-100 cursor-pointer" @click="setPriorityDate(ticket, 'tomorrow')">Tomorrow</li>
                          <li class="px-4 py-2 hover:bg-gray-100 cursor-pointer" @click="setPriorityDate(ticket, '2')">2 Days</li>
                          <li class="px-4 py-2 hover:bg-gray-100 cursor-pointer" @click="setPriorityDate(ticket, '3')">3 Days</li>
                          <li class="px-4 py-2 hover:bg-gray-100 cursor-pointer" @click="setPriorityDate(ticket, '4')">4 Days</li>
                          <li class="px-4 py-2 hover:bg-gray-100 cursor-pointer" @click="setPriorityDate(ticket, '5')">5 Days</li>
                          <li class="px-4 py-2 hover:bg-gray-100 cursor-pointer" @click="setPriorityDate(ticket, '6')">6 Days</li>
                          <li class="px-4 py-2 hover:bg-gray-100 cursor-pointer" @click="setPriorityDate(ticket, 'next_week')">Next Week</li>
                          <li class="px-4 py-2 hover:bg-gray-100 cursor-pointer" @click="setPriorityDate(ticket, 'two_week')">Two Weeks</li>
                        </ul>
                      </div>
                    </button>
                    <button title="Add a Day" @click="adjustPriority(ticket, 1)">
                      <svg class="h-5 w-5 text-blue-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M18 15l-6-6l-6 6h12" transform="rotate(90 12 12)" /></svg>
                    </button>
                  </div>
                </td>
                <td
                  class="px-4 py-2 cursor-pointer"
                  @mouseenter="showTooltip = true"
                  @mouseleave="showTooltip = false"
                >
                  {{ ticket.clientName }} <br>
                  {{ ticket.requesterName || '' }}

                  <!-- Tooltip for Delegator and Watchers -->
                  <div
                    v-if="showTooltip && (ticket.delegator_user || ticket.watcher.length > 0)"
                    class="absolute z-10 w-64 p-2 mt-1 mb-4 text-sm text-left bg-white shadow-lg border border-gray-300 rounded-md dark:bg-gray-800 dark:border-gray-700 dark:text-white"
                  >
                    <p v-if="ticket.delegator_user">
                      <strong>Delegated By:</strong>
                      {{ ticket.delegator_user.firstname }} {{ ticket.delegator_user.lastname }}
                      <br>
                      <strong>Email:</strong> {{ ticket.delegator_user.email }}
                    </p>
                    <div v-if="ticket.watcher.length">
                      <strong>Watchers:</strong>
                      <ul>
                        <li v-for="watch in ticket.watcher" :key="watch.id">
                          {{ watch.contact.name_first }} {{ watch.contact.name_last }}
                          <br>
                          <strong>Email:</strong> {{ watch.contact.email }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </td>
                <td class="px-4 py-2">{{ ticket.assigned_to }}</td>
                <td class="px-4 py-2">
                  <div class="flex flex-row space-x-1">
                    <span :class="[
                              'inline-flex',
                              'items-center',
                              'rounded-md',
                              'px-2',
                              'py-1',
                              'text-xs',
                              'font-medium',
                              `text-indigo-700`,
                              `bg-indigo-50`,
                              `ring-indigo-700/10`,
                              'ring-1',
                              'ring-inset',
                              `dark:bg-indigo-50/20`,
                              `dark:ring-indigo-400/75`,
                              `dark:text-indigo-300`
                          ]">{{ ticket.lastjobdate ? ticket.lastjobdate : 'N/A' }}
                      </span>
                  </div>
                </td>
                <td class="px-4 py-2">
                  <span class="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10 dark:bg-blue-50/20 dark:ring-blue-400/75 dark:text-blue-300">{{ ticket.logged_time?ticket.logged_time:'N/A' }}</span>
                </td>
                <td class="px-4 py-2">
                  <span class="inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10 dark:bg-purple-50/20 dark:ring-purple-400/75 dark:text-purple-300">{{ ticket.daysold?ticket.daysold:'N/A' }}</span>
                </td>
              </tr>
              <transition name="quicknote-transition">
                <tr v-if="isQuickNoteFormVisible(ticket.id)" :key="ticket.id + '-quicknote'" class="bg-gray-100 dark:bg-gray-700">
                  <td colspan="8" class="px-4 py-2">
                    <div class="p-4 rounded-md shadow-md bg-yellow-100 dark:bg-gray-900">
                      <div class="grid grid-cols-3 gap-4 mb-2">
                        <div>
                          <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Time</label>
                          <input type="number" v-model="quickNoteTime" step="0.10" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-500 dark:border-gray-900 dark:text-gray-100" min="0">
                        </div>
                        <div>
                          <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">IA</label>
                          <input type="number" v-model="quickNoteIA" step="0.10" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-500 dark:border-gray-900 dark:text-gray-100" min="0">
                        </div>
                        <div>
                          <label class="block text-sm font-medium text-gray-700 dark:text-gray-100 mr-2">Publish</label>
                          <input type="checkbox" v-model="quickNotePublish" class="rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100">
                        </div>
                      </div>
                      <textarea v-model="quickNoteText" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-500 dark:border-gray-900 dark:text-gray-100" rows="4"></textarea>
                      <div class="mt-4 flex justify-end space-x-2">
                        <button @click="saveQuickNote(ticket.id)" class="bg-yellow-500 text-white px-4 py-2 rounded-md hover:bg-yellow-400">Save</button>
                        <button @click="toggleQuickNoteForm(ticket.id)" class="bg-gray-400 text-white px-4 py-2 rounded-md hover:bg-gray-600 dark:bg-red-700 dark:hover:bg-red-500">Cancel</button>
                      </div>
                    </div>
                  </td>
                </tr>
              </transition>
              <transition name="details-transition">
                <tr v-if="isDetailsVisible(ticket.id)" :key="ticket.id + '-details'" class="bg-gray-100 dark:bg-gray-700">
                  <td colspan="8" class="px-4 py-2">
                    <div class="p-4 rounded-md shadow-md bg-gray-50 dark:bg-gray-900">
                      <div class="flex items-center justify-right space-x-2 mb-4">
                        <button @click="addJob(ticket.id)" title="Add Job">
                          <svg class="h-5 w-5 text-blue-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z"/>
                          </svg>
                        </button>
                        <button @click="toggleQuickNoteForm(ticket.id)" title="Add Note">
                          <svg class="h-5 w-5 text-green-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <line x1="13" y1="20" x2="20" y2="13" />  <path d="M13 20v-6a1 1 0 0 1 1 -1h6v-7a2 2 0 0 0 -2 -2h-12a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7" /></svg>
                        </button>
                        <button @click="openMergeTicketModal(ticket)" title="Merge ticket" :disabled="!isEditable" class="disabled:opacity-50 disabled:cursor-not-allowed">
                          <svg class="h-5 w-5 text-cyan-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="7" cy="18" r="2" />  <circle cx="7" cy="6" r="2" />  <circle cx="17" cy="12" r="2" />  <line x1="7" y1="8" x2="7" y2="16" />  <path d="M7 8a4 4 0 0 0 4 4h4" /></svg>
                        </button>
                        <button title="Add Reminder" @click="openCreateReminderModal(ticket)">
                          <svg class="h-5 w-5 text-blue-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="12" cy="13" r="7" />  <polyline points="12 10 12 13 14 13" />  <line x1="7" y1="4" x2="4.25" y2="6" />  <line x1="17" y1="4" x2="19.75" y2="6" /></svg>
                        </button>
                        <button @click="deleteTicket(ticket.id)" title="Delete Ticket" :disabled="!isEditable" class="disabled:opacity-50 disabled:cursor-not-allowed">
                          <svg class="h-5 w-5 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                          </svg>
                        </button>
                      </div>
                      <div v-if="ticket.delegatornotes" class="mb-2"><strong>AIM Instruction-Notes: </strong><div v-html="renderMarkdown(ticket.delegatornotes)"></div></div>
                      <div v-if="ticket.privateinfo" class="mb-2"><strong>Private Notes: </strong><div v-html="renderMarkdown(ticket.privateinfo)"></div></div>
                      <!-- Timeline for ticket notes -->
                      <ol class="relative border-l border-gray-200 dark:border-gray-700">
                        <li v-for="note in ticket.notes" :key="note.id" class="mb-10 ml-6">
                          <div v-if="note">
                            <span class="absolute flex items-center justify-center w-8 h-8 bg-blue-100 rounded-full -left-4 ring-4 ring-white dark:ring-gray-900 dark:bg-blue-900">
                              <svg aria-hidden="true" class="w-3 h-3 text-blue-800 dark:text-blue-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"/>
                              </svg>
                            </span>
                            <div class="flex justify-between items-center">
                              <time class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">{{ note.ts }}</time>
                            </div>
                            <h3 class="text-lg font-semibold text-gray-900 dark:text-white" v-if="note.user_profile">{{ note.user_profile.firstname }} {{ note.user_profile.lastname }}</h3>
                            <div v-html="renderMarkdown(note.description)"></div>
                            <p class="mb-4 text-base text-xs text-gray-500 dark:text-gray-400">Total Time: {{ note.totaltime }}</p>
                          </div>
                        </li>
                      </ol>
                    </div>
                  </td>
                </tr>
              </transition>
            </template>
          </tbody>
        </table>
        <MergeTicketsModal :isVisible="mergeTicketModal" :sourceTicketId="selectedTicketId" @close="mergeTicketModal = false" @complete="handleMergeTickets()" />
        <CreateReminderModal :isVisible="createReminderModal" :sourceTicketId="selectedTicketId" :techs="allTech" :currentTech="selectedTech" @close="createReminderModal = false" @complete="handleCreateReminder()" />
        <TransferTicket :isVisible="transferTicketModal" :sourceTicketId="selectedTicketId" :techs="allTech" :currentTech="selectedTech" @close="transferTicketModal = false" @complete="handleTicketTransfer()" />
        <div v-if="ticketTypes.length > 0 && allTech.length > 0">
          <SetCompleteTicketModal :isVisible="setCompleteTicketModal" :allTypes= "ticketTypes" :currentTicketTypeId="selectedTicketTypeId" :sourceTicketId="selectedTicketId" :techs="allTech" :currentTech="selectedTech" @close="setCompleteTicketModal = false" @complete="handleCloseTicket()" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import MergeTicketsModal from "@/views/modals/MergeTicketsModal.vue";
import CreateReminderModal from "@/views/modals/CreateReminderModal.vue";
import { marked } from 'marked';
import SetCompleteTicketModal from '@/views/modals/SetCompleteTicketModal.vue';
import TransferTicket from '@/views/modals/TransferTicketModal.vue';

export default {
  components: {
    MergeTicketsModal,
    CreateReminderModal,
    SetCompleteTicketModal,
    TransferTicket
  },
  props: {
    ticketId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      filters: {
        ticket_id: this.ticketId
      },
      userId: localStorage.getItem('user_id'),
      ticket: null,
      expandedTicketId: this.ticketId,
      quickNoteFormVisible: {},
      quickNoteText: '',
      quickNoteTime: 0,
      quickNoteIA: 0,
      quickNotePublish: false,
      createReminderModal: false,
      mergeTicketModal: false,
      selectedTicketId: 0,
      selectedTech: 0,
      allTech: [],
      ticketTypes: [],
      setCompleteTicketModal: false,
      showPriorityOptions: null,
      transferTicketModal: false,
      showTooltip: false,
      selectedTicketTypeId: 0,
    };
  },
  mounted() {
    this.fetchTicketDetails();
  },
  computed: {
    isEditable() {
      return ['12', '6', '847'].includes(this.userId);
    },
  },
  methods: {
    fetchTicketDetails() {
      this.$updateLoading(1);
      const params = {
        page: this.page,
        itemsPerPage: 1,
        sort_column: 'priority',
        sort_order: 'DESC',
        ...this.filters
      };

      try {
        // Your fetch logic here
        axios.post('ticket/getAll', params)
        .then(response => {
          this.ticket = response.data.data[0];
          console.log('tickets: ', this.ticket);
          this.expandedTicketId = this.ticketId;
          this.selectedTech = this.ticket.tech_id
          this.toggleDetails(this.ticket.id);
          this.getTicketTypes(this.ticket.client.id);
          this.selectedTicketTypeId = this.ticket.tickettype_id;
        })
        .catch(error => {
          console.error(error);
        });
        axios.get(`user/techs`)
        .then((response) => {
          this.allTech = response.data;
        })
        .catch((error) => {
          console.error("There was an error fetching ticket techs:", error);
        }).finally(() => {
          this.$updateLoading(-1);
        });
      } catch (error) {
        console.error('Error fetching tickets:', error);
      }
    },
    getTicketTypes(company_id) {
      this.$updateLoading(1);
      axios.get(`ticket/get-types/${company_id}`)
      .then((response) => {
        this.ticketTypes = response.data;
      })
      .catch((error) => {
        console.error("There was an error fetching ticket types:", error);
      }).finally(() => {
          this.$updateLoading(-1);
      });
    },
    toggleDetails(ticketId) {
      this.expandedTicketId = this.expandedTicketId === ticketId ? null : ticketId;
    },
    isDetailsVisible(ticketId) {
      return this.expandedTicketId === ticketId;
    },
    startJob(ticketId) {
      window.open(`https://atms.aimitservices.com/jobList.php?func=quick_add_job_from_ticket&ticket_id=${ticketId}`, '_blank');
    },
    addJob(ticketId) {
      window.open(`https://atms.aimitservices.com/addEdit.php?ticketID=${ticketId}&return_to=tickets-summary-IMPROVED.php`, '_blank');
    },
    toggleQuickNoteForm(ticketId) {
      this.quickNoteText= '';
      this.quickNoteTime= 0;
      this.quickNoteIA= 0;
      this.quickNoteFormVisible = {
        ...this.quickNoteFormVisible,
        [ticketId]: !this.quickNoteFormVisible[ticketId]
      };
      console.log(this.quickNoteFormVisible);
      console.log(this.isQuickNoteFormVisible(ticketId));
    },
    isQuickNoteFormVisible(ticketId) {
      return this.quickNoteFormVisible[ticketId] || false;
    },
    openMergeTicketModal(ticket) {
      this.selectedTicketId = ticket.id;
      this.mergeTicketModal = true;
    },
    openCreateReminderModal(ticket) {
      this.selectedTicketId = ticket.id;
      this.createReminderModal = true;
    },
    openSetCompleteTicketModal(ticket) {
      this.selectedTicketId = ticket.id;
      this.selectedTicketTypeId = ticket.tickettype_id;
      this.setCompleteTicketModal = true;
    },
    openTransferTicketModal(ticket) {
      this.selectedTicketId = ticket.id;
      this.transferTicketModal = true;
    },
    editTicket(id) {
      this.$router.push({ name: 'editTicket', params: { id: id } });
    },
    renderMarkdown(text) {
      const html = marked.parse(this.preprocessMarkdown(text));
      // console.log(html);
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');
      doc.querySelectorAll('h2').forEach(el => {
        el.classList.add('mb-2', 'text-lg', 'font-semibold', 'text-gray-900', 'dark:text-white');
      });
      doc.querySelectorAll('h3, p').forEach(el => {
        el.classList.add('mb-2', 'text-base', 'font-semibold', 'text-gray-900', 'dark:text-white');
      });

      // Add classes to unordered lists and list items
      // doc.querySelectorAll('ul').forEach(ul => {
      //   ul.classList.add('max-w-md', 'space-y-1', 'text-gray-500', 'list-disc', 'list-inside', 'dark:text-gray-400');
      //   ul.querySelectorAll('li').forEach(li => {
      //     li.classList.add('list-item-class'); // add if specific LI classes are needed
      //   });
      // });

      // Style for all unordered lists
      doc.querySelectorAll('ul').forEach(ul => {
        ul.classList.add('max-w-md', 'space-y-1', 'text-gray-500', 'list-disc', 'list-inside', 'dark:text-gray-400');
      });

      // Additional style for nested lists
      doc.querySelectorAll('ul ul').forEach(nestedUl => {
        nestedUl.classList.add('ml-4', 'border-l', 'pl-4', 'mt-2', 'border-gray-200', 'dark:border-gray-700');
      });

      // Return the modified HTML as a string
      return doc.body.innerHTML;
    },
    preprocessMarkdown(markdownText) {
      // Split the Markdown text into lines
      const lines = markdownText.split('\n');
      const processedLines = lines.map(line => {
        // Check if the line starts with spaces followed by a dash (list item)
        // and replace leading spaces with a tab
        return line.replace(/^(\s+)-/, '\t-');
      });
      // Join the processed lines back into a single string
      return processedLines.join('\n');
    },
    togglePriorityOptions(ticketId) {
      this.showPriorityOptions = this.showPriorityOptions === ticketId ? null : ticketId;
    },
    async setPriorityDate(ticket, dayOption) {
      // Assuming you have a method to calculate the date based on the option
      const newDate = this.calculateNewDate(dayOption, ticket.ptime);
      await this.updateTicketPriorityDate(ticket.id, newDate);
      this.showPriorityOptions = null; // Close the dropdown
    },
    async adjustPriority(ticket, adjustment) {
      const adjustedDate = this.adjustDateByDays(ticket.pdate, adjustment, ticket.ptime);
      await this.updateTicketPriorityDate(ticket.id, adjustedDate);
    },
    async updateTicketPriorityDate(ticketId, newDate) {
      this.$updateLoading(1);
      try {
        const response = await axios.post('ticket/update-priority', { ticketId, newDate });
        let [datePart, timePart] = newDate.split('T');
        const shortYearDate = `${datePart.slice(2)}`;
        const ticketIndex = this.tickets.findIndex(ticket => ticket.id === ticketId);
        if (ticketIndex !== -1) {
          this.tickets[ticketIndex].pdate = shortYearDate;
        }
      } catch (error) {
        console.error('Error updating priority date:', error);
      } finally {
        this.$updateLoading(-1);
      }
    },
    calculateNewDate(dayOption, ptime) {
      const today = new Date();
      let newDate;
      switch(dayOption) {
        case 'today':
          newDate = today;
          break;
        case 'tomorrow':
          newDate = new Date(today.setDate(today.getDate() + 1));
          break;
        case '2':
          newDate = new Date(today.setDate(today.getDate() + 2));
          break;
        case '3':
          newDate = new Date(today.setDate(today.getDate() + 3));
          break;
        case '4':
          newDate = new Date(today.setDate(today.getDate() + 4));
          break;
        case '5':
          newDate = new Date(today.setDate(today.getDate() + 5));
          break;
        case '6':
          newDate = new Date(today.setDate(today.getDate() + 6));
          break;
        case 'next_week':
          newDate = new Date(today.setDate(today.getDate() + 7));
          break;
        case 'two_week':
          newDate = new Date(today.setDate(today.getDate() + 14));
          break;
        default:
          newDate = today;
      }
      return newDate.toISOString().slice(0, 10) + 'T' + ptime;
    },
    adjustDateByDays(currentDate, days, ptime) {
      const dateParts = currentDate.split('-');
      if (dateParts.length === 3) {
        const year = parseInt(dateParts[0], 10) + 2000; // Convert 'YY' to 'YYYY'
        const month = dateParts[1];
        const day = dateParts[2];
        const formattedDate = `${year}-${month}-${day}`;
        const date = new Date(formattedDate);
        const adjustedDate = new Date(date.setDate(date.getDate() + days));
        if (isNaN(adjustedDate)) {
          console.error('Invalid date:', adjustedDate);
        } else {
          return adjustedDate.toISOString().slice(0, 10) + 'T' + ptime;
        }
      }
      return
    },
    async deleteTicket(ticketId) {
        const ticket = this.tickets.find(t => t.id === ticketId);
        if (!ticket) {
            alert("Ticket not found.");
            return;
        }

        // Prevent deletion if worked time exists
        if (ticket.logged_time > 0) {
            alert(`Cannot delete. This ticket has ${ticket.logged_time} hours of worked time.`);
            return;
        }

        const tmp = confirm(`Are you sure you would like to delete ticket #${ticket.id}?`);
        if (!tmp) {
            return;
        }

        this.$updateLoading(1);
        try {
            const response = await axios.post('ticket/delete', { delete: ticketId });
            alert(response.data.message);
            this.fetchTickets(); // Refresh the tickets list
        } catch (error) {
            console.error('Error deleting ticket:', error);
            alert(error.response.data.message || 'Error deleting ticket');
        } finally {
            this.$updateLoading(-1);
        }
    },
  }
};
</script>

<style scoped>
.details-transition-enter-active, .details-transition-leave-active {
  transition: all 0.1s ease;
}
.details-transition-enter, .details-transition-leave-to /* .details-transition-leave-active in <2.1.8 */ {
  opacity: 0;
  transform: translateY(-10px);
}

.quicknote-transition-enter-active, .quicknote-transition-leave-active {
  transition: all 0.1s ease;
}
.quicknote-transition-enter, .quicknote-transition-leave-to /* .quicknote-transition-leave-active in <2.1.8 */ {
  opacity: 0;
  transform: translateY(-10px);
}

.rotate-180 {
  transform: rotate(180deg);
}

.transition-transform {
  transition: transform 0.1s ease;
}
</style>