<template>
  <div v-if="isVisible" class="fixed inset-0 bg-gray-600 bg-opacity-70 z-50 flex justify-center items-center p-4">
    <div class="bg-white dark:bg-gray-800 p-6 rounded-lg w-full max-w-md">
      <h2 class="text-lg font-semibold dark:text-gray-100 mb-4">Complete Ticket: #{{ sourceTicketId }}</h2>
      
      <div class="mb-4">
        <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Completed Date</label>
        <input type="date" v-model="day" class="w-full p-2 rounded-md border-gray-300 shadow-sm text-xs dark:bg-gray-500 dark:border-gray-600 dark:text-gray-100">
      </div>
      
      <div class="mb-4">
        <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Ticket Type</label>
        <select v-model="selectedTicketTypeId" class="w-full p-2 rounded-md border-gray-300 shadow-sm text-xs dark:bg-gray-500 dark:border-gray-600 dark:text-gray-100">
          <option v-for="type in allTypes" :key="type.id" :value="type.id">{{ type.description }}</option>
        </select>
      </div>

      <div class="mb-4">
        <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Completed By</label>
        <select v-model="selectedTech" class="w-full p-2 rounded-md border-gray-300 shadow-sm text-xs dark:bg-gray-500 dark:border-gray-600 dark:text-gray-100">
          <option v-for="tech in techs" :key="tech.id" :value="tech.id">{{ tech.name }}</option>
        </select>
      </div>

      <div class="mb-4">
        <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Closing Note</label>
        <textarea v-model="closingNote" rows="3" class="w-full p-2 rounded-md border-gray-300 shadow-sm dark:bg-gray-500 dark:border-gray-600 dark:text-gray-100"></textarea>
      </div>

      <div class="flex items-center mb-4">
        <input type="checkbox" v-model="sendEmail" class="mr-2 rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100">
        <label for="sendEmail" class="text-sm font-medium text-gray-700 dark:text-gray-100">Send email notification</label>
      </div>

      <div class="flex justify-end space-x-2 mt-4">
        <button @click="closeModal()" class="bg-gray-400 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded dark:bg-red-500 dark:hover:bg-red-700">Cancel</button>
        <button @click="save()" class="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded dark:bg-yellow-500 dark:hover:bg-yellow-700">Mark Complete</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      required: true
    },
    sourceTicketId: {
      type: Number,
      required: true
    },
    techs: {
      type: Array,
      required: true
    },
    allTypes: {
      type: Array,
      required: true
    },
    currentTech: {
      type: Number,
      required: true
    },
    currentTicketTypeId: {
      type: Number,
      required: true
    }
  },
  data() {
    const today = new Date().toISOString().substr(0, 10);
    return {
      selectedTech: this.currentTech,
      selectedTicketTypeId: this.currentTicketTypeId,
      day: today,
      sendEmail: false,
      closingNote: '',
      showReqTypeDropdown: false,
      // techs: [
      //   { id: 1, name: 'Gali, Mary' },
      //   { id: 2, name: 'Tech 2' },
      //   // Add more techs here
      // ]
    };
  },
  watch: {
    currentTech(newVal) {
      this.selectedTech = newVal;
    },
    currentTicketTypeId(newVal) {
      this.selectedTicketTypeId = newVal;
    }
  },
  mounted() {
    this.selectedTech= this.currentTech;
    this.selectedTicketTypeId = this.currentTicketTypeId;
  },
  methods: {
    closeModal() {
      this.selectedTech = '';
      this.selectedTicketTypeId = '';
      this.day = '';
      this.sendEmail = false;
      this.closingNote = '';
      this.$emit('close');
    },
    save() {
      if (this.selectedTech && this.day && this.closingNote && this.selectedTech) {
        this.$emit('complete', {
          ticketId: this.sourceTicketId,
          techId: this.selectedTech,
          day: this.day,
          sendEmail: this.sendEmail ? 1: 0,
          closingNote: this.closingNote,
          ticketTypeId: this.selectedTicketTypeId
        });
        this.closeModal();
      } else {
        alert('Please fill in all fields.');
      }
    }
  }
};
</script>
<style>
.dark-mode .scrollable-requester-list {
  background-color: #2d3748;
  color: #a0aec0;
}

.scrollable-requester-list {
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid #ccc;
}
</style>
