import { createStore } from 'vuex';
import auth from './modules/auth';

export default createStore({
  modules: {
    auth
  },
  state: {
    logo: localStorage.getItem('logo') || 'aim_logo.png',
    shouldReloadDelegatorTickets: false,
  },
  mutations: {
    setLogo(state, logoName) {
        state.logo = logoName;
    },
    triggerReloadDelegatorTickets(state) {
      state.shouldReloadDelegatorTickets = !state.shouldReloadDelegatorTickets;
    }
  },
  actions: {
    async fetchLogo({ commit }) {
      try {
          const response = await axios.get('/getlogo');
          const logoName = response.data.logoname || 'aim_logo.png';
          commit('setLogo', logoName);
          localStorage.setItem('logo', logoName);
      } catch (error) {
          console.error('Error fetching logo:', error);
      }
    }
  },
});