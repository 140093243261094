<template>
    <div class="max-w-full mx-auto my-5 border rounded-2xl px-6 py-6 dark:bg-gray-600 dark:border-gray-600">
      <div class="flex justify-between items-center mb-4">
        <div class="flex space-x-4">
          <select v-model="filters.status" @change="fetchTickets" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100">
            <option value="All">All Tickets</option>
            <option value="Open">Open</option>
            <option value="Closed">Closed</option>
          </select>

          <!-- Clients with Open Tickets Filter -->
          <select v-model="filters.client_id" @change="fetchTickets" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100">
            <option value="All">All Clients</option>
            <option v-for="client in clientsWithOpenTickets" :key="client.id" :value="client.id">
              {{ client.companyname }}
            </option>
          </select>
        </div>
        <div class="flex items-center justify-left space-x-2" @click="toggleFilters">
          <button  class="bg-yellow-500 text-white px-4 py-2 rounded-md flex items-center font-medium hover:bg-yellow-400">
            <svg class="h-6 w-6 text-white" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z"/>
              <path d="M5.5 5h13a1 1 0 0 1 0.5 1.5L14 12L14 19L10 16L10 12L5 6.5a1 1 0 0 1 0.5 -1.5"/>
            </svg>
            Filter
          </button>
          <button class="focus:outline-none transition-transform duration-200">
            <svg :class="{ 'rotate-180': showFilters }" class="w-4 h-4 text-gray-600 dark:text-white transition-transform duration-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 10">
                <path d="M15.434 1.235A2 2 0 0 0 13.586 0H2.414A2 2 0 0 0 1 3.414L6.586 9a2 2 0 0 0 2.828 0L15 3.414a2 2 0 0 0 .434-2.179Z"/>
            </svg>
          </button>
        </div>
      </div>
      <div v-if="showFilters" class="mb-4 p-4 bg-yellow-100 rounded-md dark:bg-gray-800">
        <!-- Advanced Filters -->
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div v-click-outside="closeClientDropdown">
            <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Client</label>
            <!-- <select v-model="filters.client_id" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100">
              <option value="All">All</option>
            </select> -->
            <input type="text"
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100"
                placeholder="Client Name" v-model="companyName"
                @focus="focusClient" @blur="emptyClient" />
            <ul v-if="showClientDropdown"
              class="absolute z-10 bg-white mt-1 rounded-md shadow-lg w-100 dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 scrollable-requester-list">
              <li v-for="client in filteredClients" :key="client.id" @click="setClient(client)"
                class="cursor-pointer p-3 hover:bg-gray-100 dark:hover:bg-gray-800">
                {{ client.companyname }}
              </li>
            </ul>
          </div>
          <div v-click-outside="closeReqDropdown">
            <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Requester</label>
            <input type="text"
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100"
                placeholder="Requester Name" v-model="filters.requester"
                @focus="focusReq" @blur="emptyReq" />
            <ul v-if="showReqDropdown"
              class="absolute z-10 bg-white mt-1 rounded-md shadow-lg w-100 dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 scrollable-requester-list">
              <div v-for="req in filteredRequesters" :key="req.id">
                <li @click="setReq(req)" v-if="req.name && req.name != ' '"
                  class="cursor-pointer p-3 hover:bg-gray-100 dark:hover:bg-gray-800">
                  {{ req.name }}
                </li>
              </div>
            </ul>
          </div>
          <div v-click-outside="closeTypeDropdown">
            <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Type</label>
            <!-- <select v-model="filters.type" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100">
              <option value="All">All Types</option>
            </select> -->
            <input type="text"
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100"
                placeholder="Type" v-model="filters.type" @focus="focusReqType" @blur="emptyType"/>
              <ul v-if="showReqTypeDropdown"
                class="absolute z-10 bg-white mt-1 rounded-md shadow-lg w-100 dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100 scrollable-requester-list">
                <li v-for="reqtype in filteredReqTypes" :key="reqtype.id" @click="setReqType(reqtype)"
                  class="cursor-pointer p-3 hover:bg-gray-100 dark:hover:bg-gray-800">
                  {{ reqtype.description }}
                </li>
              </ul>
          </div>
          <div>
            <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Category</label>
            <select v-model="filters.category_id" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100">
              <option value="All">All</option>
              <option v-for="category in categories" :key="category.id" :value="category.id">{{category.description }}</option>
            </select>
          </div>
          <div>
            <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Aging</label>
            <select v-model="filters.aging" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100">
              <option value="All">All</option>
              <option value="new">New</option>
              <option value="1_2w">1-2 Weeks</option>
              <option value="2_4w">2-4 Weeks</option>
              <option value="1_3m">1-3 Months</option>
              <option value="3m">3+ Months</option>
            </select>
          </div>
          <div>
            <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Billing Type</label>
            <select v-model="filters.billingType" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100">
              <option value="All">All Types</option>
              <option v-for="billingType in billingTypes" :key="billingType" :value="billingType" >{{billingType}}</option>
              <!-- Populate with actual billing types -->
            </select>
          </div>
          <div>
            <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Tech</label>
            <select v-model="filters.tech_id" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100">
              <option value="All">All</option>
              <option v-for="tech in allTech" :key="tech.id" :value="tech.id" >{{tech.name}}</option>
              <!-- Populate with actual techs -->
            </select>
          </div>
          <div>
            <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Department</label>
            <select v-model="filters.department" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100">
              <option value="All">All</option>
              <option v-for="department in departments" :key="department.id" :value="department.id" >{{department.name}}</option>
              <!-- Populate with actual departments -->
            </select>
          </div>
          <div>
            <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Start Date</label>
            <input type="date" v-model="filters.startDate" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100">
          </div>
          <div>
            <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">End Date</label>
            <input type="date" v-model="filters.endDate" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100">
          </div>
          <!-- Add the date_type filter radio buttons here -->
          <div>
            <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Date Type</label>
            <div class="flex space-x-4">
              <label class="flex items-center">
                <input type="radio" value="submitted" v-model="filters.date_type" class="form-radio text-blue-600"/>
                <span class="ml-2 text-gray-700 dark:text-gray-100">Submitted</span>
              </label>
              <label class="flex items-center">
                <input type="radio" value="last_activity" v-model="filters.date_type" class="form-radio text-blue-600"/>
                <span class="ml-2 text-gray-700 dark:text-gray-100">Last Activity</span>
              </label>
              <label class="flex items-center">
                <input type="radio" value="completed" v-model="filters.date_type" class="form-radio text-blue-600"/>
                <span class="ml-2 text-gray-700 dark:text-gray-100">Completed</span>
              </label>
              <label class="flex items-center">
                <input type="radio" value="promised" v-model="filters.date_type" class="form-radio text-blue-600"/>
                <span class="ml-2 text-gray-700 dark:text-gray-100">Priority/Promised</span>
              </label>
            </div>
          </div>
          <div>
            <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Past Due Only</label>
            <input type="checkbox" v-model="filters.pastdueonly" class="mt-1 block rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100">
          </div>
        </div>
        <div class="mt-4 flex justify-between">
          <button @click="applyFilters" class="bg-yellow-500 text-white px-4 py-2 rounded-md hover:bg-yellow-400">Set Filters</button>
          <button @click="clearFilters" class="bg-gray-400 text-white px-4 py-2 rounded-md hover:bg-gray-600 dark:bg-red-700 dark:hover:bg-red-500">Clear Filters</button>
        </div>
      </div>
      <!-- Data Table -->
      <div class="bg-gray-100 dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
        <div class="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
          <div class="w-full md:w-1/2">
            <form class="flex items-center" @submit.prevent="applyFilters">
              <label for="simple-search" class="sr-only">Search</label>
              <div class="relative w-full">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                  </svg>
                </div>
                <input type="text" id="simple-search" v-model="filters.keywords" @input="debouncedFetchTickets" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Search" />
              </div>
              <button type="submit" class="hidden">Search</button>
            </form>
          </div>
          <div class="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
            <div class="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
              <button @click="exportTicketsToCSV" type="button" class="flex items-center justify-center text-gray-100 bg-gray-400 hover:bg-yellow-500 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800">
                <svg class="h-5 w-5 text-white mr-2" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8" />
                  <polyline points="16 6 12 2 8 6" />
                  <line x1="12" y1="2" x2="12" y2="15" />
                </svg>
                Export
              </button>
              </div>
          </div>
        </div>
        <div class="overflow-x-auto">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
              <th scope="col" class="px-4 py-3"></th> <!-- Add an empty header for the collapse icon -->
              <th scope="col" class="px-4 py-3 cursor-pointer" @click="sortTickets('id')">ID
                <span :class="getSortClass('id')"></span>
              </th>
                            <th scope="col" class="px-4 py-3">Actions</th>
              <th scope="col" class="px-4 py-3">Details</th>
              <th scope="col" class="px-4 py-3 cursor-pointer" @click="sortTickets('submitted')">Submitted
                <span :class="getSortClass('submitted')"></span>
              </th>
              <th scope="col" class="px-4 py-3 cursor-pointer" @click="sortTickets('priority')">Priority
                <span :class="getSortClass('priority')"></span>
              </th>
              <th scope="col" class="px-4 py-3 cursor-pointer" @click="sortTickets('requester')">Requested By
                <span :class="getSortClass('requester')"></span>
              </th>
              <th scope="col" class="px-4 py-3 cursor-pointer" @click="sortTickets('assigned_to')">Assigned To
                <span :class="getSortClass('assigned_to')"></span>
              </th>
              <th scope="col" class="px-4 py-3 cursor-pointer" @click="sortTickets('last_activity')">Last Activity
                <span :class="getSortClass('last_activity')"></span>
              </th>
              <th scope="col" class="px-4 py-3 cursor-pointer" @click="sortTickets('logged_time')">Hours
                <span :class="getSortClass('logged_time')"></span>
              </th>
              <th scope="col" class="px-4 py-3 cursor-pointer" @click="sortTickets('daysold')">Days Old
                <span :class="getSortClass('daysold')"></span>
              </th>
            </tr>
            </thead>
            <tbody class="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
              <template v-for="ticket in tickets" :key="ticket.id">
                <tr class="hover:bg-gray-100 dark:hover:bg-gray-700">
                  <td class="px-4 py-2">
                    <button @click="toggleDetails(ticket.id)" class="focus:outline-none transition-transform duration-200">
                      <svg :class="{ 'rotate-180': isDetailsVisible(ticket.id) }" class="h-6 w-6 text-gray-500 dark:text-gray-400 transition-transform duration-200" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z"/>
                        <path d="M6 9l6 6 6-6H6z"/>
                      </svg>
                    </button>
                  </td>
                  <td class="px-4 py-2">
                    <button :class="[
                              'inline-flex',
                              'items-center',
                              'rounded-md',
                              'px-2',
                              'py-1',
                              'text-xs',
                              'font-medium',
                              `text-${ticket.urgencycolor}-700`,
                              `bg-${ticket.urgencycolor}-50`,
                              `ring-${ticket.urgencycolor}-700/10`,
                              'ring-1',
                              'ring-inset',
                              `dark:bg-${ticket.urgencycolor}-50/20`,
                              `dark:ring-${ticket.urgencycolor}-400/75`,
                              `dark:text-${ticket.urgencycolor}-300`
                            ]" @click="openTicket(ticket.id)">{{ ticket.id }}
                      </button>
                  </td>
                  <td class="px-4 py-2">
                    <div class="flex items-center justify-left space-x-2">
                      <button @click="startJob(ticket.id)" title="Start Job">
                        <svg class="h-5 w-5 text-red-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <polygon points="10 8 16 12 10 16 10 8" /></svg>
                      </button>
                      <button title="Mark as Complete" @click="openSetCompleteTicketModal(ticket.id)">
                        <svg class="h-5 w-5 text-green-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />  <polyline points="22 4 12 14.01 9 11.01" /></svg>
                      </button>
                      <button title="Transfer" @click="openTransferTicketModal(ticket.id)">
                        <svg class="h-5 w-5 text-blue-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="5" cy="18" r="2" />  <circle cx="19" cy="6" r="2" />  <path d="M19 8v5a5 5 0 0 1 -5 5h-3l3 -3m0 6l-3 -3" />  <path d="M5 16v-5a5 5 0 0 1 5 -5h3l-3 -3m0 6l3 -3" /></svg>
                      </button>
                      <button @click="editTicket(ticket.id)" title="Edit Ticket">
                        <svg class="h-5 w-5 text-yellow-500" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                          <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                          <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
                        </svg>
                      </button>
                    </div>
                  </td>
                  <td class="px-4 py-2 font-medium text-gray-900 dark:text-white" @click="toggleDetails(ticket.id)">
                    <div>{{ ticket.redmineissueid != 0 ? ticket.redmineissueid : '' }}</div>
                    <div v-if="isDetailsVisible(ticket.id)">
                      <div v-html="renderMarkdown(ticket.description)"></div>
                    </div>
                    <div v-else>
                      <div v-html="renderMarkdown(ticket.desc_short)"></div>
                    </div>
                    <!-- {{ ticket.ticket_type ? ticket.ticket_type.description : 'Description' }}: -->
                    <!-- {{ isDetailsVisible(ticket.id) ? (ticket.description || 'N/A') : (ticket.desc_short || '') }} -->
                  </td>
                  <td class="px-4 py-2 whitespace-nowrap" @click="toggleDetails(ticket.id)">{{ ticket.submitted }}</td>
                  <td class="px-4 py-2 whitespace-nowrap">
                    <div class="flex items-center justify-left space-x-2">
                      <button title="Back a day" @click="adjustPriority(ticket, -1)">
                        <svg class="h-5 w-5 text-blue-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M18 15l-6-6l-6 6h12" transform="rotate(270 12 12)" /></svg>
                      </button>
                      <button  @click="togglePriorityOptions(ticket.id)" class="relative">{{ ticket.pdate }} {{ ticket.ptime }}
                        <div v-if="showPriorityOptions === ticket.id" class="absolute z-10 mt-1 bg-white shadow-lg w-48 rounded-md">
                          <ul class="text-gray-700">
                            <li class="px-4 py-2 hover:bg-gray-100 cursor-pointer" @click="setPriorityDate(ticket, 'today')">Today</li>
                            <li class="px-4 py-2 hover:bg-gray-100 cursor-pointer" @click="setPriorityDate(ticket, 'tomorrow')">Tomorrow</li>
                            <li class="px-4 py-2 hover:bg-gray-100 cursor-pointer" @click="setPriorityDate(ticket, '2')">2 Days</li>
                            <li class="px-4 py-2 hover:bg-gray-100 cursor-pointer" @click="setPriorityDate(ticket, '3')">3 Days</li>
                            <li class="px-4 py-2 hover:bg-gray-100 cursor-pointer" @click="setPriorityDate(ticket, '4')">4 Days</li>
                            <li class="px-4 py-2 hover:bg-gray-100 cursor-pointer" @click="setPriorityDate(ticket, '5')">5 Days</li>
                            <li class="px-4 py-2 hover:bg-gray-100 cursor-pointer" @click="setPriorityDate(ticket, '6')">6 Days</li>
                            <li class="px-4 py-2 hover:bg-gray-100 cursor-pointer" @click="setPriorityDate(ticket, 'next_week')">Next Week</li>
                            <li class="px-4 py-2 hover:bg-gray-100 cursor-pointer" @click="setPriorityDate(ticket, 'two_week')">Two Weeks</li>
                          </ul>
                        </div>
                      </button>
                      <button title="Add a Day" @click="adjustPriority(ticket, 1)">
                        <svg class="h-5 w-5 text-blue-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M18 15l-6-6l-6 6h12" transform="rotate(90 12 12)" /></svg>
                      </button>
                    </div>
                  </td>
                  <td
                    class="px-4 py-2 cursor-pointer"
                    @mouseenter="toggleTooltip(ticket.id)"
                    @mouseleave="toggleTooltip(null)"
                  >
                    {{ ticket.clientName }} <br>
                    {{ ticket.requesterName || '' }}

                    <!-- Tooltip for Delegator and Watchers -->
                    <div
                      v-if="isTooltipVisible(ticket.id) && (ticket.delegator_user || ticket.watcher.length > 0)"
                      class="absolute z-10 w-64 p-2 mt-1 mb-4 text-sm text-left bg-white shadow-lg border border-gray-300 rounded-md dark:bg-gray-800 dark:border-gray-700 dark:text-white"
                    >
                      <p v-if="ticket.delegator_user">
                        <strong>Delegated By:</strong>
                        {{ ticket.delegator_user.firstname }} {{ ticket.delegator_user.lastname }}
                        <br>
                        <strong>Email:</strong> {{ ticket.delegator_user.email }}
                      </p>
                      <div v-if="ticket.watcher.length">
                        <strong>Watchers:</strong>
                        <ul>
                          <li v-for="watch in ticket.watcher" :key="watch.id">
                            {{ watch.contact.name_first }} {{ watch.contact.name_last }}
                            <br>
                            <strong>Email:</strong> {{ watch.contact.email }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </td>
                  <td class="px-4 py-2" @click="toggleDetails(ticket.id)">{{ ticket.assigned_to }}</td>
                  <td class="px-4 py-2" @click="toggleDetails(ticket.id)">
                    <!-- <div class="flex flex-row space-x-1">                    </div> -->
                      <span :class="[
                              'inline-flex',
                              'items-center',
                              'rounded-md',
                              'px-2',
                              'py-1',
                              'text-xs',
                              'font-medium',
                              `text-indigo-700`,
                              `bg-indigo-50`,
                              `ring-indigo-700/10`,
                              'ring-1',
                              'ring-inset',
                              `dark:bg-indigo-50/20`,
                              `dark:ring-indigo-400/75`,
                              `dark:text-indigo-300`
                            ]">{{ ticket.lastjobdate ? ticket.lastjobdate : 'N/A' }}
                        </span>
                      <!-- <span :class="getUrgencyClass(ticket.urgencycolor)">
                        {{ ticket.lastjobdate ? ticket.lastjobdate : 'N/A' }}
                      </span> -->
                      <!-- <span class="inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10 dark:bg-purple-50/20 dark:ring-purple-400/75 dark:text-purple-300">{{ ticket.lastjobdate?ticket.lastjobdate:'N/A' }}</span> -->

                  </td>
                  <td class="px-4 py-2" @click="toggleDetails(ticket.id)">
                    <span class="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10 dark:bg-blue-50/20 dark:ring-blue-400/75 dark:text-blue-300">{{ ticket.logged_time?ticket.logged_time:'N/A' }}</span>
                  </td>
                  <td class="px-4 py-2" @click="toggleDetails(ticket.id)">
                    <span class="inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10 dark:bg-purple-50/20 dark:ring-purple-400/75 dark:text-purple-300">{{ ticket.daysold?ticket.daysold:'N/A' }}</span>
                  </td>
                </tr>
                <transition name="quicknote-transition">
                  <tr v-if="isQuickNoteFormVisible(ticket.id)" :key="ticket.id + '-quicknote'" class="bg-gray-100 dark:bg-gray-700">
                    <td colspan="8" class="px-4 py-2">
                      <div class="p-4 rounded-md shadow-md bg-yellow-100 dark:bg-gray-900">
                        <div class="grid grid-cols-3 gap-4 mb-2">
                          <div>
                            <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Time</label>
                            <input type="number" v-model="quickNoteTime" step="0.10" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-500 dark:border-gray-900 dark:text-gray-100" min="0">
                          </div>
                          <div>
                            <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">IA</label>
                            <input type="number" v-model="quickNoteIA" step="0.10" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-500 dark:border-gray-900 dark:text-gray-100" min="0">
                          </div>
                          <div>
                            <label class="block text-sm font-medium text-gray-700 dark:text-gray-100 mr-2">Publish</label>
                            <input type="checkbox" v-model="quickNotePublish" class="rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100">
                          </div>
                        </div>
                        <textarea v-model="quickNoteText" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-500 dark:border-gray-900 dark:text-gray-100" rows="4"></textarea>
                        <div class="mt-4 flex justify-end space-x-2">
                          <button @click="saveQuickNote(ticket.id)" class="bg-yellow-500 text-white px-4 py-2 rounded-md hover:bg-yellow-400">Save</button>
                          <button @click="toggleQuickNoteForm(ticket.id)" class="bg-gray-400 text-white px-4 py-2 rounded-md hover:bg-gray-600 dark:bg-red-700 dark:hover:bg-red-500">Cancel</button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </transition>
                <transition name="details-transition">
                  <tr v-if="isDetailsVisible(ticket.id)" :key="ticket.id + '-details'" class="bg-gray-100 dark:bg-gray-700">
                    <td colspan="8" class="px-4 py-2">
                      <div class="p-4 rounded-md shadow-md bg-gray-50 dark:bg-gray-900 mb-4">
                        <div class="flex items-center justify-right space-x-2 mb-4">
                          <button @click="addJob(ticket.id)" title="Add Job">
                            <svg class="h-5 w-5 text-blue-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z"/>
                            </svg>
                          </button>
                          <button @click="toggleQuickNoteForm(ticket.id)" title="Add Note">
                            <svg class="h-5 w-5 text-green-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <line x1="13" y1="20" x2="20" y2="13" />  <path d="M13 20v-6a1 1 0 0 1 1 -1h6v-7a2 2 0 0 0 -2 -2h-12a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7" /></svg>
                          </button>
                          <button @click="openMergeTicketModal(ticket.id)" title="Merge ticket" :disabled="!isEditable" class="disabled:opacity-50 disabled:cursor-not-allowed">
                            <svg class="h-5 w-5 text-cyan-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="7" cy="18" r="2" />  <circle cx="7" cy="6" r="2" />  <circle cx="17" cy="12" r="2" />  <line x1="7" y1="8" x2="7" y2="16" />  <path d="M7 8a4 4 0 0 0 4 4h4" /></svg>
                          </button>
                          <button title="Add Reminder" @click="openCreateReminderModal(ticket.id)">
                            <svg class="h-5 w-5 text-blue-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="12" cy="13" r="7" />  <polyline points="12 10 12 13 14 13" />  <line x1="7" y1="4" x2="4.25" y2="6" />  <line x1="17" y1="4" x2="19.75" y2="6" /></svg>
                          </button>
                          <button @click="deleteTicket(ticket.id)" title="Delete Ticket" :disabled="!isEditable" class="disabled:opacity-50 disabled:cursor-not-allowed">
                            <svg class="h-5 w-5 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                            </svg>
                          </button>
                        </div>
                        <div v-if="ticket.delegatornotes" class="mb-2"><strong>AIM Instruction-Notes: </strong><div v-html="renderMarkdown(ticket.delegatornotes)"></div></div>
                        <div v-if="ticket.privateinfo" class="mb-2"><strong>Private Notes: </strong><div v-html="renderMarkdown(ticket.privateinfo)"></div></div>
                        <!-- Timeline for ticket notes -->
                        <ol class="relative border-l border-gray-200 dark:border-gray-700">
                          <li v-for="note in ticket.notes" :key="note.id" class="mb-10 ml-6">
                            <div v-if="note">
                              <span class="absolute flex items-center justify-center w-8 h-8 bg-blue-100 rounded-full -left-4 ring-4 ring-white dark:ring-gray-900 dark:bg-blue-900">
                                <svg aria-hidden="true" class="w-3 h-3 text-blue-800 dark:text-blue-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"/>
                                </svg>
                              </span>
                              <div class="flex justify-between items-center">
                                <time class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">{{ note.ts }}</time>
                              </div>
                              <h3 class="text-lg font-semibold text-gray-900 dark:text-white" v-if="note.user_profile">{{ note.user_profile.firstname }} {{ note.user_profile.lastname }}</h3>
                              <div v-html="renderMarkdown(note.description)"></div>
                              <p class="mb-4 text-base text-xs text-gray-500 dark:text-gray-400">Total Time: {{ note.totaltime }}</p>
                            </div>
                          </li>
                        </ol>
                      </div>
                    </td>
                  </tr>
                </transition>
              </template>
            </tbody>
          </table>
          <MergeTicketsModal :isVisible="mergeTicketModal" :sourceTicketId="selectedTicketId" @close="mergeTicketModal = false" @complete="handleMergeTickets" />
          <CreateReminderModal :isVisible="createReminderModal" :sourceTicketId="selectedTicketId" :techs="allTech" :currentTech="selectedTech" @close="createReminderModal = false" @complete="handleCreateReminder" />
          <TransferTicket :isVisible="transferTicketModal" :sourceTicketId="selectedTicketId" :techs="allTech" :currentTech="selectedTech" @close="transferTicketModal = false" @complete="handleTicketTransfer" />
          <div v-if="ticketTypes.length > 0 && allTech.length > 0">
            <SetCompleteTicketModal :isVisible="setCompleteTicketModal" :allTypes= 'ticketTypes' :currentTicketTypeId="selectedTicketTypeId" :sourceTicketId="selectedTicketId" :techs="allTech" :currentTech="selectedTech" @close="setCompleteTicketModal = false" @complete="handleCloseTicket" />
          </div>
        </div>
        <nav class="flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 p-4" aria-label="Table navigation">
          <span class="text-sm font-normal text-gray-500 dark:text-gray-400">
            Showing
            <span class="font-semibold text-gray-900 dark:text-white">{{ startItem }}-{{ endItem }}</span>
            of
            <span class="font-semibold text-gray-900 dark:text-white">{{ totalTickets }}</span>
          </span>
          <ul class="inline-flex items-stretch -space-x-px">
            <li>
              <button @click="handlePageChange(page - 1)" :disabled="page === 1" class="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                <span class="sr-only">Previous</span>
                <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
              </button>
            </li>
            <li v-for="pageNum in visiblePages" :key="pageNum">
              <button @click="handlePageChange(pageNum)" :class="{ 'bg-primary-50 dark:bg-gray-700 text-primary-600 dark:text-white': pageNum === page, 'hover:bg-gray-100 hover:text-gray-700 dark:hover:bg-gray-700 dark:hover:text-white': pageNum !== page }" class="flex items-center justify-center text-sm py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400">
                {{ pageNum }}
              </button>
            </li>
            <li>
              <button @click="handlePageChange(page + 1)" :disabled="page === totalPages" class="flex items-center justify-center h-full py-1.5 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                <span class="sr-only">Next</span>
                <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                </svg>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
</template>

<script>
import axios from 'axios';
import MergeTicketsModal from "@/views/modals/MergeTicketsModal.vue";
import CreateReminderModal from "@/views/modals/CreateReminderModal.vue";
import SetCompleteTicketModal from '@/views/modals/SetCompleteTicketModal.vue';
import TransferTicket from '@/views/modals/TransferTicketModal.vue';
import { marked } from 'marked';

export default {
  components: {
    MergeTicketsModal,
    CreateReminderModal,
    SetCompleteTicketModal,
    TransferTicket
  },
  data() {
    return {
      tickets: [],
      totalTickets: 0,
      userId: localStorage.getItem('user_id'),
      userDepartmentId: 'All',
      page: 1,
      itemsPerPage: 15,
      showFilters: false,
      sortKey: 'priority', // default sort column
      sortOrder: 'desc', // default sort order
      companyName: 'All',
      showClientDropdown: false,
      selectedTech: parseInt(localStorage.getItem('user_id'), 10) || 0,
      showReqDropdown: false,
      showReqTypeDropdown: false,
      createReminderModal: false,
      setCompleteTicketModal: false,
      showPriorityOptions: null,
      transferTicketModal: false,
      showTooltip: false,
      selectedTicketTypeId: 0,
      mergeTicketModal: false,
      selectedTicketId: 0,
      clientsWithOpenTickets: [],
      clients: [],
      requesters: [],
      ticketTypes: [],
      categories: [],
      billingTypes: [],
      allTech: [],
      departments: [],
      filters: {
        status: 'Open',
        date_type: '',
        client_id: 'All',
        requester_id:'All',
        requester: 'All',
        type: 'All',
        type_id: 'All',
        category_id: 'All',
        aging: 'All',
        billingType: 'All',
        tech_id: localStorage.getItem('user_id'),
        department: 'All',
        startDate: '',
        endDate: '',
        keywords: '',
        pastdueonly: false // New filter for past due only
      },
      expandedTicketId: null, // Track which ticket is expanded
      debouncedFetchTickets: this.debounce(this.fetchTickets, 500),
      quickNoteFormVisible: {},
      quickNoteText: '',
      quickNoteTime: 0,
      quickNoteIA: 0,
      quickNotePublish: false,
    };
  },
  computed: {
    filteredClients () {
      return this.companyName
        ? this.clients.filter((e) =>
          e.companyname
            .toLowerCase()
            .includes(this.companyName.toLowerCase())
        )
        : this.clients;
    },
    filteredRequesters() {
      return this.filters.requester
        ? this.requesters.filter((e) =>
            e.name.toLowerCase().includes(this.filters.requester.toLowerCase())
          ).slice(0, 20)
        : this.requesters;
    },
    filteredReqTypes() {
      return this.filters.type
        ? this.ticketTypes.filter((e) =>
          e.description
            .toLowerCase()
            .includes(this.filters.type.toLowerCase())
        )
        : this.ticketTypes;
    },
    totalPages() {
      return Math.ceil(this.totalTickets / this.itemsPerPage);
    },
    visiblePages() {
      const pages = [];
      if (this.totalPages <= 5) {
        for (let i = 1; i <= this.totalPages; i++) {
          pages.push(i);
        }
      } else {
        if (this.page <= 3) {
          pages.push(1, 2, 3, 4, '...', this.totalPages);
        } else if (this.page > this.totalPages - 3) {
          pages.push(1, '...', this.totalPages - 3, this.totalPages - 2, this.totalPages - 1, this.totalPages);
        } else {
          pages.push(1, '...', this.page - 1, this.page, this.page + 1, '...', this.totalPages);
        }
      }
      return pages;
    },
    startItem() {
      return (this.page - 1) * this.itemsPerPage + 1;
    },
    endItem() {
      return Math.min(this.page * this.itemsPerPage, this.totalTickets);
    },
    isEditable() {
      return ['12', '6', '847'].includes(this.userId);
    },
  },
  methods: {
    async init() {
      try {
        axios.get(`user/profile/${this.userId}`)
        .then(response => {
          this.userDepartmentId = response.data.aimdept;
          this.filters.department = this.userDepartmentId;
        })
        .catch(error => {
          console.error(error);
        });
        axios.get('clients/')
        .then(response => {
          this.clients = response.data;
        })
        .catch(error => {
          console.error(error);
        });
        axios.get(`clients/open-tickets/${this.userId}`)
        .then(response => {
          this.clientsWithOpenTickets = response.data;
        })
        .catch(error => {
          console.error(error);
        });
        axios.get('requesters/')
        .then(response => {
          this.requesters = response.data;
        })
        .catch(error => {
          console.error(error);
        });

        axios.get('departments/')
        .then(response => {
          this.departments = response.data;
        })
        .catch(error => {
          console.error(error);
        });

        axios.get(`user/techs`)
        .then((response) => {
          this.allTech = response.data;
        })
        .catch((error) => {
          console.error("There was an error fetching ticket techs:", error);
        });
        let newVal = 30
        if (this.filters.client_id != '' && this.filters.client_id != 'All') {
          newVal = this.filters.client_id;
        }
        axios.get(`ticket/get-types/${newVal}`)
        .then((response) => {
          this.ticketTypes = response.data;
        })
        .catch((error) => {
          console.error("There was an error fetching ticket types:", error);
        });

        axios.get(`ticket/get-categories/${newVal}`)
        .then((response) => {
          this.categories = response.data;
        })
        .catch((error) => {
          console.error("There was an error fetching ticket types:", error);
        });

        axios.get(`clients/billing-types/`)
        .then((response) => {
          this.billingTypes = response.data.billingtypes;
        })
        .catch((error) => {
          console.error("There was an error fetching ticket types:", error);
        });
      } catch (error) {
        console.error('Error fetching tickets:', error);
      }
    },
    async setPriorityDate(ticket, dayOption) {
      // Assuming you have a method to calculate the date based on the option
      const newDate = this.calculateNewDate(dayOption, ticket.ptime);
      await this.updateTicketPriorityDate(ticket.id, newDate);
      this.showPriorityOptions = null; // Close the dropdown
    },
    async adjustPriority(ticket, adjustment) {
      const adjustedDate = this.adjustDateByDays(ticket.pdate, adjustment, ticket.ptime);
      await this.updateTicketPriorityDate(ticket.id, adjustedDate);
    },
    async updateTicketPriorityDate(ticketId, newDate) {
      this.$updateLoading(1);
      try {
        const response = await axios.post('ticket/update-priority', { ticketId, newDate });
        let [datePart, timePart] = newDate.split('T');
        const shortYearDate = `${datePart.slice(2)}`;
        const ticketIndex = this.tickets.findIndex(ticket => ticket.id === ticketId);
        if (ticketIndex !== -1) {
          this.tickets[ticketIndex].pdate = shortYearDate;
        }
      } catch (error) {
        console.error('Error updating priority date:', error);
      } finally {
        this.$updateLoading(-1);
      }
    },
    calculateNewDate(dayOption, ptime) {
      const today = new Date();
      let newDate;
      switch(dayOption) {
        case 'today':
          newDate = today;
          break;
        case 'tomorrow':
          newDate = new Date(today.setDate(today.getDate() + 1));
          break;
        case '2':
          newDate = new Date(today.setDate(today.getDate() + 2));
          break;
        case '3':
          newDate = new Date(today.setDate(today.getDate() + 3));
          break;
        case '4':
          newDate = new Date(today.setDate(today.getDate() + 4));
          break;
        case '5':
          newDate = new Date(today.setDate(today.getDate() + 5));
          break;
        case '6':
          newDate = new Date(today.setDate(today.getDate() + 6));
          break;
        case 'next_week':
          newDate = new Date(today.setDate(today.getDate() + 7));
          break;
        case 'two_week':
          newDate = new Date(today.setDate(today.getDate() + 14));
          break;
        default:
          newDate = today;
      }
      return newDate.toISOString().slice(0, 10) + 'T' + ptime;
    },
    adjustDateByDays(currentDate, days, ptime) {
      const dateParts = currentDate.split('-');
      if (dateParts.length === 3) {
        const year = parseInt(dateParts[0], 10) + 2000; // Convert 'YY' to 'YYYY'
        const month = dateParts[1];
        const day = dateParts[2];
        const formattedDate = `${year}-${month}-${day}`;
        const date = new Date(formattedDate);
        const adjustedDate = new Date(date.setDate(date.getDate() + days));
        if (isNaN(adjustedDate)) {
          console.error('Invalid date:', adjustedDate);
        } else {
          return adjustedDate.toISOString().slice(0, 10) + 'T' + ptime;
        }
      }
      return
    },
    togglePriorityOptions(ticketId) {
      this.showPriorityOptions = this.showPriorityOptions === ticketId ? null : ticketId;
    },
    preprocessMarkdown(markdownText) {
      // Split the Markdown text into lines
      const lines = markdownText.split('\n');
      const processedLines = lines.map(line => {
        // Check if the line starts with spaces followed by a dash (list item)
        // and replace leading spaces with a tab
        return line.replace(/^(\s+)-/, '\t-');
      });
      // Join the processed lines back into a single string
      return processedLines.join('\n');
    },
    renderMarkdown(text) {
      const html = marked.parse(this.preprocessMarkdown(text));
      // console.log(html);
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');
      doc.querySelectorAll('h2').forEach(el => {
        el.classList.add('mb-2', 'text-lg', 'font-semibold', 'text-gray-900', 'dark:text-white');
      });
      doc.querySelectorAll('h3, p').forEach(el => {
        el.classList.add('mb-2', 'text-base', 'font-semibold', 'text-gray-900', 'dark:text-white');
      });

      // Add classes to unordered lists and list items
      // doc.querySelectorAll('ul').forEach(ul => {
      //   ul.classList.add('max-w-md', 'space-y-1', 'text-gray-500', 'list-disc', 'list-inside', 'dark:text-gray-400');
      //   ul.querySelectorAll('li').forEach(li => {
      //     li.classList.add('list-item-class'); // add if specific LI classes are needed
      //   });
      // });

      // Style for all unordered lists
      doc.querySelectorAll('ul').forEach(ul => {
        ul.classList.add('max-w-md', 'space-y-1', 'text-gray-500', 'list-disc', 'list-inside', 'dark:text-gray-400');
      });

      // Additional style for nested lists
      doc.querySelectorAll('ul ul').forEach(nestedUl => {
        nestedUl.classList.add('ml-4', 'border-l', 'pl-4', 'mt-2', 'border-gray-200', 'dark:border-gray-700');
      });

      // Return the modified HTML as a string
      return doc.body.innerHTML;
    },
    toggleTooltip(ticketId) {
      // Toggle the tooltip visibility by checking if it's already showing for the current requester
      if (this.showTooltip === ticketId) {
        this.showTooltip = null; // Hide the tooltip if it's the current requester
      } else {
        this.showTooltip = ticketId; // Show the tooltip for the new requester
      }
    },
    isTooltipVisible(ticketId) {
      // Check if the tooltip should be visible for the requester
      return this.showTooltip === ticketId;
    },
    fetchTickets() {
      this.$updateLoading(1);
      const params = {
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        sort_column: this.sortKey,
        sort_order: this.sortOrder.toUpperCase(),
        ...this.filters
      };

      try {
        // Your fetch logic here
        axios.post('ticket/getAll', params)
        .then(response => {
          this.tickets = response.data.data;
          this.totalTickets = response.data.total;
          // console.log('tickets: ', this.tickets);
          // console.log('totalTickets: ', this.tickets);
        })
        .catch(error => {
          console.error(error);
        }).finally(() => {
          this.$updateLoading(-1);
        });
      } catch (error) {
        console.error('Error fetching tickets:', error);
      }
    },
    async saveQuickNote(ticketId) {
      try {
        const ticket = this.tickets.find(t => t.id === ticketId);
        if (!ticket) {
          throw new Error(`Ticket with id ${ticketId} not found`);
        }
        const response = await axios.post('job/add-note', {
          client_id: ticket.company_id,
          ticket_id: ticket.id,
          contact_id: ticket.contact_id,
          description: this.quickNoteText,
          time: this.quickNoteTime,
          ia: this.quickNoteIA,
          publish: this.quickNotePublish? 1: 0
        });
        console.log(response);
        this.toggleQuickNoteForm(ticketId);
        this.fetchTickets();
      } catch (error) {
        console.error('Error saving quick note:', error);
      }
    },
    handleMergeTickets({ sourceTicketId, targetTicketId, reason }) {
      this.$updateLoading(1);
      axios.post('ticket/merge', { sourceTicketId:sourceTicketId, targetTicketId:targetTicketId, reason:reason })
        .then(response => {
          this.fetchTickets(); // Refresh the tickets list
          alert('Tickets merged successfully.');
        })
        .catch(error => {
          console.error('Error merging tickets:', error);
        }).finally(() => {
          this.$updateLoading(-1);
        });
    },
    handleCreateReminder({ ticketId, techId, day, time, sendText, description }) {
      this.$updateLoading(1);
      const ticket = this.tickets.find(t => t.id === ticketId);
      if (!ticket) {
        throw new Error(`Ticket with id ${ticketId} not found`);
      }
      axios.post('ticket/create-reminder', { 
        ticketId: ticketId, 
        techId: techId, 
        day: day, 
        time: time, 
        sendText: sendText?1:0, 
        description: description, 
        contact_id: ticket.contact_id, 
        tickettype_id: ticket.tickettype_id, 
        company_id: ticket.company_id })
        .then(response => {
          this.fetchTickets(); // Refresh the tickets list
          alert('Reminder created successfully.');
        })
        .catch(error => {
          console.error('Error creating reminder:', error);
        }).finally(() => {
          this.$updateLoading(-1);
        });
    },
    handleTicketTransfer({ ticketId, curTechId, newTechId, sendEmail, transferNotes }) {
      this.$updateLoading(1);
      const ticket = this.tickets.find(t => t.id === ticketId);
      if (!ticket) {
        throw new Error(`Ticket with id ${ticketId} not found`);
      }
      axios.post('ticket/transfer', { 
        ticketId: ticketId, 
        curTechId: curTechId, 
        newTechId: newTechId, 
        sendEmail: sendEmail, 
        transferNotes: transferNotes})
        .then(response => {
          this.fetchTickets(); // Refresh the tickets list
          alert('Ticket Transferred successfully.');
        })
        .catch(error => {
          console.error('Error transferring ticket:', error);
        }).finally(() => {
          this.$updateLoading(-1);
        });
    },
    handleCloseTicket({ ticketId, techId, day, sendEmail, closingNote, ticketTypeId }) {
      this.$updateLoading(1);
      const ticket = this.tickets.find(t => t.id === ticketId);
      if (!ticket) {
        throw new Error(`Ticket with id ${ticketId} not found`);
      }
      axios.post('ticket/close', { 
        ticketId: ticketId, 
        techId: techId, 
        day: day, 
        sendEmail: sendEmail, 
        closingNote: closingNote, 
        ticketTypeId: ticketTypeId})
        .then(response => {
          this.fetchTickets(); // Refresh the tickets list
          alert('Ticket Closed successfully.');
        })
        .catch(error => {
          console.error('Error closing ticket:', error);
        }).finally(() => {
          this.$updateLoading(-1);
        });
    },
    sortTickets(column) {
      if (this.sortKey === column) {
        // Toggle sort order if the same column is clicked
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
      } else {
        // Set the new sort key and default to ascending order
        this.sortKey = column;
        this.sortOrder = 'asc';
      }
      this.fetchTickets();
    },
    getSortClass(column) {
      if (this.sortKey === column) {
        return this.sortOrder === 'asc' ? 'sort-asc' : 'sort-desc';
      }
      return '';
    },
    // getUrgencyClass(urgencycolor) {
    //   console.log(urgencycolor);
    //   return [
    //     'inline-flex',
    //     'items-center',
    //     'rounded-md',
    //     'px-2',
    //     'py-1',
    //     'text-xs',
    //     'font-medium',
    //     'text-' + urgencycolor + '-700',
    //     'bg-' + urgencycolor + '-50',
    //     'ring-' + urgencycolor + '-700/10',
    //     'ring-1',
    //     'ring-inset',
    //     'dark:bg-' + urgencycolor + '-50/20',
    //     'dark:ring-' + urgencycolor + '-400/75',
    //     'dark:text-' + urgencycolor + '-300'
    //   ];
    // },
    toggleFilters() {
      this.showFilters = !this.showFilters;
    },
    applyFilters() {
      this.page = 1;
      this.fetchTickets();
    },
    clearFilters() {
      this.filters = {
        status: 'Open',
        date_type: '',
        client_id: 'All',
        requester_id:'All',
        requester: 'All',
        type: 'All',
        type_id: 'All',
        category_id: 'All',
        aging: 'All',
        billingType: 'All',
        tech_id: localStorage.getItem('user_id'),
        department: this.userDepartmentId,
        startDate: '',
        endDate: '',
        keywords: '',
        pastdueonly: false // New filter for past due only
      };
      this.fetchTickets();
    },
    debounce(func, wait, immediate) {
      let timeout;
      return function() {
        const context = this, args = arguments;
        const later = function() {
          timeout = null;
          if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
      };
    },
    exportTicketsToCSV() {
      // Convert the tickets data to CSV
      let csvContent = "data:text/csv;charset=utf-8,";
      const headers = ["ID", "Details", "Priority", "Requested By", "Assigned To", "Last Activity", "Logged Time", "Days Old"];
      csvContent += headers.join(",") + "\r\n";
      
      this.tickets.forEach(ticket => {
        const row = [
          ticket.id,
          `"${(ticket.description || ticket.desc_short).replace(/"/g, '""')}"`, // Escape quotes in description
          `"${ticket.pdate} ${ticket.ptime}"`,
          `"${ticket.clientName}"`,
          `"${ticket.assigned_to}"`,
          `"${ticket.lastjobdate || 'N/A'}"`,
          `"${ticket.logged_time || 'N/A'}"`,
          `"${ticket.daysold || 'N/A'}"`
        ];
        csvContent += row.join(",") + "\r\n";
      });

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "tickets.csv");
      document.body.appendChild(link); // Required for FF

      link.click();
    },
    handlePageChange(newPage) {
      if (newPage !== '...' && newPage > 0 && newPage <= this.totalPages) {
        this.page = newPage;
        this.fetchTickets(); // Fetch tickets based on the new page
      }
    },
    openMergeTicketModal(ticketId) {
      this.selectedTicketId = ticketId;
      this.mergeTicketModal = true;
    },
    openCreateReminderModal(ticketId) {
      const ticket = this.tickets.find(t => t.id === ticketId);
      if (!ticket) {
        console.error(`Ticket with id ${ticketId} not found`);
        return; // Exit the function if no ticket is found
      }
      this.selectedTicketId = ticketId;
      this.selectedTech = ticket.tech_id;
      this.selectedTicketTypeId = ticket.ticket_type.id;
      this.createReminderModal = true;
    },
    openSetCompleteTicketModal(ticketId) {
      const ticket = this.tickets.find(t => t.id === ticketId);
      if (!ticket) {
        console.error(`Ticket with id ${ticketId} not found`);
        return; // Exit the function if no ticket is found
      }
      this.selectedTicketId = ticketId;
      this.selectedTicketTypeId = ticket.ticket_type.id;
      this.setCompleteTicketModal = true;
    },
    openTransferTicketModal(ticketId) {
      const ticket = this.tickets.find(t => t.id === ticketId);
      if (!ticket) {
        console.error(`Ticket with id ${ticketId} not found`);
        return; // Exit the function if no ticket is found
      }
      this.selectedTicketId = ticketId;
      this.selectedTech = ticket.tech_id;
      this.transferTicketModal = true;
    },
    toggleDetails(ticketId) {
      this.expandedTicketId = this.expandedTicketId === ticketId ? null : ticketId;
    },
    isDetailsVisible(ticketId) {
      return this.expandedTicketId === ticketId;
    },
    startJob(ticketId) {
      window.open(`https://atms.aimitservices.com/jobList.php?func=quick_add_job_from_ticket&ticket_id=${ticketId}`, '_blank');
    },
    addJob(ticketId) {
      window.open(`https://atms.aimitservices.com/addEdit.php?ticketID=${ticketId}&return_to=tickets-summary-IMPROVED.php`, '_blank');
    },
    toggleQuickNoteForm(ticketId) {
      this.quickNoteText= '';
      this.quickNoteTime= 0;
      this.quickNoteIA= 0;
      this.quickNoteFormVisible = {
        ...this.quickNoteFormVisible,
        [ticketId]: !this.quickNoteFormVisible[ticketId]
      };
      console.log(this.quickNoteFormVisible);
      console.log(this.isQuickNoteFormVisible(ticketId));
    },
    isQuickNoteFormVisible(ticketId) {
      return this.quickNoteFormVisible[ticketId] || false;
    },
    editTicket(id) {
      this.$router.push({ name: 'editTicket', params: { id: id } });
    },
    openTicket(id) {
      this.$router.push({ name: 'ticketDetails', params: { ticketId: id } });
    },
    async deleteTicket(ticketId) {
        const ticket = this.tickets.find(t => t.id === ticketId);
        if (!ticket) {
            alert("Ticket not found.");
            return;
        }

        // Prevent deletion if worked time exists
        if (ticket.logged_time > 0) {
            alert(`Cannot delete. This ticket has ${ticket.logged_time} hours of worked time.`);
            return;
        }

        const tmp = confirm(`Are you sure you would like to delete ticket #${ticket.id}?`);
        if (!tmp) {
            return;
        }

        this.$updateLoading(1);
        try {
            const response = await axios.post('ticket/delete', { delete: ticketId });
            alert(response.data.message);
            this.fetchTickets(); // Refresh the tickets list
        } catch (error) {
            console.error('Error deleting ticket:', error);
            alert(error.response.data.message || 'Error deleting ticket');
        } finally {
            this.$updateLoading(-1);
        }
    },
    closeClientDropdown () {
      this.showClientDropdown = false;
    },
    closeReqDropdown () {
      this.showReqDropdown = false;
    },
    closeTypeDropdown () {
      this.showReqTypeDropdown = false;
    },
    focusClient () {
      if(this.companyName == 'All') {
        this.companyName = '';
        this.filters.client_id = '';
      }
      this.showClientDropdown = true;
    },
    focusReq () {
      if(this.filters.requester == 'All') {
        this.filters.requester = '';
        this.filters.requester_id = '';
      }
      this.showReqDropdown = true;
    },
    focusReqType () {
      if(this.filters.type == 'All') {
        this.filters.type = '';
        this.filters.type_id = '';
      }
      this.showReqTypeDropdown = true;
    },
    emptyReq() {
      setTimeout(() => {
        if (this.filters.requester === '') {
          this.filters.requester = 'All';
          this.filters.requester_id = '';
        }
      }, 200);
    },
    emptyClient() {
      setTimeout(() => {
        if (this.companyName === '') {
          this.companyName = 'All';
          this.filters.client_id = '';
        }
      }, 200);
    },
    emptyType() {
      setTimeout(() => {
        if (this.filters.type === '') {
          this.filters.type = 'All';
          this.filters.type_id = '';
        }
      }, 200);
    },
    setClient (client) {
      this.filters.client_id = client.id;
      this.companyName = client.companyname;
      this.showClientDropdown = false;
    },
    setReq(req) {
      this.filters.requester = req.name;
      this.filters.requester_id = req.id;
      this.showReqDropdown = false;
    },
    setReqType (type) {
      this.filters.type_id = type.id;
      this.filters.type = type.description;
      this.showReqTypeDropdown = false;
    }
  },
  mounted() {
    this.init()
    this.fetchTickets();
  }
};
</script>

<style scoped>
.dark-mode .scrollable-requester-list {
  background-color: #2d3748;
  color: #a0aec0;
}

.scrollable-requester-list {
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid #ccc;
}

.details-transition-enter-active, .details-transition-leave-active {
  transition: all 0.1s ease;
}
.details-transition-enter, .details-transition-leave-to /* .details-transition-leave-active in <2.1.8 */ {
  opacity: 0;
  transform: translateY(-10px);
}

.quicknote-transition-enter-active, .quicknote-transition-leave-active {
  transition: all 0.1s ease;
}
.quicknote-transition-enter, .quicknote-transition-leave-to /* .quicknote-transition-leave-active in <2.1.8 */ {
  opacity: 0;
  transform: translateY(-10px);
}

.rotate-180 {
  transform: rotate(180deg);
}

.transition-transform {
  transition: transform 0.1s ease;
}

.sort-asc::after {
  content: '▲';
  font-size: 0.75rem;
  margin-left: 0.5rem;
}

.sort-desc::after {
  content: '▼';
  font-size: 0.75rem;
  margin-left: 0.5rem;
}
</style>
