<template>
  <div class="flex flex-col items-center max-w-sm mx-auto sm:pt-10">
    <div class="flex flex-row">
      <div class="flex flex-col">
        <img class="h-20 object-contain mb-4" src="/assets/aim_logo_big.png" alt="logo" />
        <h1 class="text-2xl mb-2">Set Your Password</h1>
        <form @submit.prevent="submit" class="space-y-4">
          <div>
            <label for="password" class="block text-sm">Password</label>
            <input id="password" type="password" v-model="account.password" @blur="validatePassword" :class="passwordError ? 'border-red-500' : ''" class="w-full rounded border px-2 py-1" />
            <div v-if="passwordError" class="text-red-500">{{ passwordError }}</div>
          </div>
          <div>
            <label for="repeatPassword" class="block text-sm">Repeat Password</label>
            <input id="repeatPassword" type="password" v-model="account.password_confirmation" @blur="validateRepeatPassword" :class="repeatPasswordError ? 'border-red-500' : ''" class="w-full rounded border px-2 py-1" />
            <div v-if="repeatPasswordError" class="text-red-500">{{ repeatPasswordError }}</div>
          </div>
          <button type="submit" :class="isSubmitting ? 'bg-gray-300' : 'bg-red-500'" class="w-full text-white rounded px-4 py-2 hover:bg-red-700" :disabled="isSubmitting">Submit</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import AccountService from '@/services/AccountService'
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default {
  name: 'ForgotPassword',
  data() {
    return {
      account: { password: '', password_confirmation: '', token: '', email: '' },
      passwordError: '', repeatPasswordError: '', isSubmitting: false
    };
  },
  computed: {
    getLogoPath () {
      const store = useStore();
      return `/assets/logos/${store.state.logo || localStorage.getItem('logo')}`;
    },
  },
  created() {
    const route = useRoute();
    this.account.token = route.query.token || '';
    this.account.email = route.query.email || this.$route.params.email || '';
  },
  methods: {
    validatePassword() { this.passwordError = this.account.password.length < 8 ? 'Password must be at least 8 characters long' : ''; },
    validateRepeatPassword() { this.repeatPasswordError = this.account.password !== this.account.password_confirmation ? 'Passwords do not match' : ''; },
    async submit() {
      this.isSubmitting = true;
      this.validatePassword(); this.validateRepeatPassword();
      if (!this.passwordError && !this.repeatPasswordError) {
        this.$updateLoading(1);
        try {
          const payload = { password: this.account.password, password_confirmation: this.account.password_confirmation, token: this.account.token, email: this.account.email };
          const status = await AccountService.resetPassword(payload);
          this.$router.push({ path: '/login' });
        } catch (error) { alert(error.message) }
        finally { 
          this.isSubmitting = false;
          this.$updateLoading(-1);
        }
      }
    }
  }
};
</script>
