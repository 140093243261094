<template>
  <div class="max-w-full mx-auto my-5 border rounded-2xl px-6 py-6 dark:bg-gray-600 dark:border-gray-600">
    <!-- Tabs Section -->
    <div class="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
      <ul class="flex flex-wrap -mb-px">
        <li v-for="tab in tabs" :key="tab.id" class="me-2">
          <button 
            @click="changeTab(tab.id)"
            class="inline-block p-4 border-b-2 border-transparent rounded-t-lg"
            :class="activeTab === tab.id 
              ? 'text-yellow-600 border-yellow-600 dark:text-yellow-500 dark:border-yellow-500' 
              : 'hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'">
            {{ tab.description }} ({{ tab.devices.length }})
          </button>
        </li>
      </ul>
    </div>

    <div class="filter-container">
      <div class="flex justify-between items-center mb-4 mt-8 mr-4">
        <div class="flex space-x-4">
        </div>
        <!-- Toggle Button -->
        <div class="flex items-center justify-left space-x-2" @click="toggleFilters">
          <button  class="bg-yellow-500 text-white px-4 py-2 rounded-md flex items-center font-medium hover:bg-yellow-400">
            <svg class="h-6 w-6 text-white" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z"/>
              <path d="M5.5 5h13a1 1 0 0 1 0.5 1.5L14 12L14 19L10 16L10 12L5 6.5a1 1 0 0 1 0.5 -1.5"/>
            </svg>
            Filter
          </button>
          <button class="focus:outline-none transition-transform duration-200">
            <svg :class="{ 'rotate-180': showFilters }" class="w-4 h-4 text-gray-600 dark:text-white transition-transform duration-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 10">
                <path d="M15.434 1.235A2 2 0 0 0 13.586 0H2.414A2 2 0 0 0 1 3.414L6.586 9a2 2 0 0 0 2.828 0L15 3.414a2 2 0 0 0 .434-2.179Z"/>
            </svg>
          </button>
        </div>
      </div>

      <!-- Filter Section -->
      <transition name="fade">
        <div v-if="showFilters" class="filter-panel p-4 bg-gray-100 rounded-md dark:bg-gray-800 shadow-md">
          <!-- Selected Filters Chips -->
          <!-- Selected Filters Display -->
          <div class="flex flex-wrap mb-4">
            <span v-for="(value, key) in selectedFilters" :key="key" class="bg-yellow-300 text-black px-3 py-1 rounded-full text-sm flex items-center m-1">
              {{ key }}: {{ value }}
              <button @click="clearFilter(key)" class="ml-2 text-gray-700 hover:text-red-500">
                ✕
              </button>
            </span>
          </div>

          <!-- Multi-Select Dropdowns -->
          <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
            <!-- Location Filter -->
            <div>
              <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Location</label>
              <div class="relative">
                <button @click="toggleDropdown('location')" class="w-full border border-gray-300 dark:border-gray-600 rounded-lg py-2 px-4 bg-white dark:bg-gray-700 text-left">
                  {{ getDisplayText(tabFilters[activeTab].location, location) }}
                  <span class="absolute right-3 top-3 text-gray-500">▼</span>
                </button>
                <div v-if="dropdowns.location" class="absolute mt-2 w-full bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg shadow-lg z-50">
                  <div class="p-2 max-h-60 overflow-y-auto">
                    <!-- Select All Option -->
                    <label class="flex items-center space-x-2 py-1 cursor-pointer">
                      <input type="checkbox" @change="toggleSelectAll('location')" :checked="tabFilters[activeTab].location.length === location.length">
                      <span>All</span>
                    </label>
                    <!-- Individual Options -->
                    <label v-for="option in location" :key="option.id" class="flex items-center space-x-2 py-1 cursor-pointer">
                      <input type="checkbox" v-model="tabFilters[activeTab].location" :value="option">
                      <span>{{ option.name }}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <!-- User Filter -->
            <div>
              <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">User</label>
              <div class="relative">
                <button @click="toggleDropdown('user')" class="w-full border border-gray-300 dark:border-gray-600 rounded-lg py-2 px-4 bg-white dark:bg-gray-700 text-left">
                  {{ getDisplayText(tabFilters[activeTab].user, user) }}
                  <span class="absolute right-3 top-3 text-gray-500">
                    ▼
                  </span>
                </button>
                <div v-if="dropdowns.user" class="absolute mt-2 w-full bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg shadow-lg z-50">
                  <div class="p-2 max-h-60 overflow-y-auto">
                    <label class="flex items-center space-x-2 py-1 cursor-pointer">
                      <input type="checkbox" @change="toggleSelectAll('user')" :checked="tabFilters[activeTab].user.length === user.length">
                      <span>All</span>
                    </label>
                    <label class="flex items-center space-x-2 py-1 cursor-pointer" v-for="option in user" :key="option.id">
                      <input type="checkbox" v-model="tabFilters[activeTab].user" :value="option">
                      <span>{{ option.name }}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <!-- Operating System Filter -->
            <div>
              <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Operating System</label>
              <div class="relative">
                <button @click="toggleDropdown('os')" class="w-full border border-gray-300 dark:border-gray-600 rounded-lg py-2 px-4 bg-white dark:bg-gray-700 text-left">
                  {{ getDisplayText(tabFilters[activeTab].os, os) }}
                  <span class="absolute right-3 top-3 text-gray-500">
                    ▼
                  </span>
                </button>
                <div v-if="dropdowns.os" class="absolute mt-2 w-full bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg shadow-lg z-50">
                  <div class="p-2 max-h-60 overflow-y-auto">
                    <label class="flex items-center space-x-2 py-1 cursor-pointer">
                      <input type="checkbox" @change="toggleSelectAll('os')" :checked="tabFilters[activeTab].os.length === os.length">
                      <span>All</span>
                    </label>
                    <label class="flex items-center space-x-2 py-1 cursor-pointer" v-for="option in os" :key="option.id">
                      <input type="checkbox" v-model="tabFilters[activeTab].os" :value="option">
                      <span>{{ option.name }}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Action Buttons -->
          <div class="flex justify-between mt-4">
            <button @click="applyFilters" class="bg-yellow-500 text-white px-4 py-2 rounded-md hover:bg-yellow-400">Apply Filters</button>
            <button @click="clearAllFilters" class="bg-gray-400 text-white px-4 py-2 rounded-md hover:bg-gray-600">Clear All</button>
          </div>
        </div>
      </transition>
    </div>

    <!-- Search & Export -->
    <div class="bg-gray-100 dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden mt-">
      <div class="flex flex-col md:flex-row items-center justify-between p-4">
        <input 
          type="text" 
          v-model="searchQuery" 
          @input="debouncedFetchAssets" 
          class="w-full md:w-1/3 px-4 py-2 border rounded-lg dark:bg-gray-700 dark:text-white"
          placeholder="Search assets..."
        >
        <button @click="exportAssetsToCSV" class="bg-gray-400 hover:bg-yellow-500 text-white px-4 py-2 rounded-md">
          Export
        </button>
      </div>

      <!-- Assets Table -->
      <div class="overflow-x-auto">
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th class="px-4 py-3">PC Name</th>
              <th class="px-4 py-3">Location</th>
              <th class="px-4 py-3">Description</th>
              <th class="px-4 py-3">Domain</th>
              <th class="px-4 py-3">User</th>
              <th class="px-4 py-3">CPU</th>
              <th class="px-4 py-3">Manufacturer</th>
              <th class="px-4 py-3">Mem</th>
              <th class="px-4 py-3">OS</th>
              <th class="px-4 py-3">BIOS Date</th>
              <th class="px-4 py-3">External IP</th>
              <th class="px-4 py-3">Internal IP</th>
              <th class="px-4 py-3">Last Reported</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="device in paginatedDevices" :key="device.id" class="hover:bg-gray-100 dark:hover:bg-gray-700">
              <td class="px-4 py-2">{{ device.name }}</td>
              <td class="px-4 py-2">{{ device.location_id && device.location ? device.location.name : '' }}</td>
              <td class="px-4 py-2">{{ device.description }}</td>
              <td class="px-4 py-2">{{ device.workgroup }}</td>
              <td class="px-4 py-2">{{ device.user }}</td>
              <td class="px-4 py-2">{{ device.cpu }}</td>
              <td class="px-4 py-2">{{ device.manufacturer }}</td>
              <td class="px-4 py-2">{{ device.memory }}</td>
              <td class="px-4 py-2">{{ device.osname }}</td>
              <td class="px-4 py-2">{{ device.biosdate }}</td>
              <td class="px-4 py-2">{{ device.externalip }}</td>
              <td class="px-4 py-2">{{ device.internalip }}</td>
              <td class="px-4 py-2">{{ device.ts }}</td>
            </tr>
          </tbody>
        </table>
      </div>


      <!-- Pagination Controls -->
      <div class="flex justify-end items-center mt-4 px-4">
        <button 
          @click="prevPage" 
          :disabled="currentPage === 1" 
          class="px-4 py-2 bg-gray-400 text-white rounded-md hover:bg-gray-500 disabled:opacity-50 mr-4">
          Previous
        </button>
        <span class="text-sm text-gray-700 dark:text-gray-300 mr-2">
          Page {{ currentPage }} of {{ totalPages }}
        </span>
        <button 
          @click="nextPage" 
          :disabled="currentPage === totalPages" 
          class="px-4 py-2 bg-gray-400 text-white rounded-md hover:bg-gray-500 disabled:opacity-50">
          Next
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { debounce } from 'lodash';
import Multiselect from '@vueform/multiselect';

export default {
  components: { Multiselect },
  data() {
    return {
      tabs: [],
      activeTab: null,
      assets: [],
      currentPage: 1,
      itemsPerPage: 10,
      searchQuery: '',
      showFilters: false,
      userId: localStorage.getItem('user_id'),
      companyId: null,
      tabFilters: {},
      // filters: {
      //   location: [],
      //   user: [],
      //   os: []
      // },
      dropdowns: {
        location: false,
        user: false,
        os: false
      },
      location: [],
      user: [],
      os: []
    };
  },
  computed: {
    filteredAssets() {
      if (!this.searchQuery) return this.assets;
      return this.assets.filter(asset =>
        asset.pc_name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
        asset.os.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },

    totalPages() {
      return Math.ceil(this.filteredDevices.length / this.itemsPerPage);
    },

    paginatedDevices() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredDevices.slice(start, end);
    },

    selectedFilters() {
      return {
        Location: this.filters.location.length === this.location.length
          ? 'All'
          : this.filters.location.map(l => l.name).join(", "),
        User: this.filters.user.length === this.user.length
          ? 'All'
          : this.filters.user.map(u => u.name).join(", "),
        OS: this.filters.os.length === this.os.length
          ? 'All'
          : this.filters.os.map(o => o.name).join(", ")
      };
    },
    filteredDevices() {
      if (!Array.isArray(this.tabs)) {
        return [];
      }
      const activeTabData = this.tabs.find(tab => tab.id === this.activeTab);
      if (!activeTabData) {
        return [];
      }
      const filters = this.tabFilters[this.activeTab] || { location: [], user: [], os: [] };

      return activeTabData.devices.filter(device => {
        return (
          (filters.location.length === this.location.length || filters.location.some(loc => loc.id === device.location_id)) &&
          (filters.user.length === this.user.length || filters.user.some(user => user.name === device.user)) &&
          (filters.os.length === this.os.length || filters.os.some(os => os.name === device.osname))
        );
      });
    },
  },
  methods: {
    async fetchAssets() {
      try {
        this.$updateLoading(1);
        const response = await axios.get(`/clients/assets/${this.companyId}`);
        this.tabs = response.data.tabs;
        this.location = response.data.locations;
        if (this.tabs.length > 0) {
          this.activeTab = this.tabs[0].id;
          // console.log('activetab1', this.activeTab);
        }
        this.initializeFilters();
        // console.log('activetab', this.activeTab);
        // console.log('tabs', this.tabs);
        this.$updateLoading(-1);
      } catch (error) {
        this.$updateLoading(-1);
        console.error('Error fetching assets:', error);
      }
    },
    initializeFilters() {
      const allUsers = new Set();
      const allOperatingSystems = new Set();
      this.tabs.forEach(tab => {
        tab.devices.forEach(device => {
          if (device.user && device.user.length > 0) {
            allUsers.add(device.user);
          }
          if (device.osname && device.osname.length > 0) {
            allOperatingSystems.add(device.osname);
          }
        });
      });
      this.tabs.forEach(tab => {
        if (!this.tabFilters[tab.id]) {
          this.tabFilters[tab.id] = {
            location: [...this.location],
            user: Array.from(allUsers).sort().map(name => ({ name, id: name })),
            os: Array.from(allOperatingSystems).sort().map(name => ({ name, id: name }))
          };
        }
      });
      this.user = Array.from(allUsers).sort().map(name => ({ name, id: name }));
      this.os = Array.from(allOperatingSystems).sort().map(name => ({ name, id: name }));
    },
    toggleFilters() {
      this.showFilters = !this.showFilters;
    },
    toggleDropdown(filterType) {
      Object.keys(this.dropdowns).forEach(key => {
        if (key !== filterType) {
          this.dropdowns[key] = false;
        }
      });

      this.dropdowns[filterType] = !this.dropdowns[filterType];
    },
    toggleSelectAll(filterType) {
      const currentFilters = this.tabFilters[this.activeTab][filterType];
      if (currentFilters.length === this[filterType].length) {
        this.tabFilters[this.activeTab][filterType] = [];
      } else {
        this.tabFilters[this.activeTab][filterType] = [...this[filterType]];
      }
    },
    getDisplayText(selected, allOptions) {
      return selected.length === allOptions.length
        ? 'All'
        : selected.length > 0
          ? selected.map(option => option.name).join(", ")
          : "Select options...";
    },
    applyFilters() {
      const filters = this.tabFilters[this.activeTab];
      this.filteredDevices = this.tabs.find(tab => tab.id === this.activeTab).devices.filter(device => {
        return (
          (filters.location.length === this.location.length || filters.location.some(loc => loc.id === device.location_id)) &&
          (filters.user.length === this.user.length || filters.user.some(user => user.name === device.user)) &&
          (filters.os.length === this.os.length || filters.os.some(os => os.name === device.osname))
        );
      });
      this.currentPage = 1;
    },
    clearFilter(key) {
      this.tabFilters[this.activeTab][key.toLowerCase()] = [];
      this.applyFilters();
    },
    clearAllFilters() {
      this.tabFilters[this.activeTab] = { location: this.location, user: this.user, os: this.os};
      this.applyFilters();
    },

    changeTab(tabId) {
      this.activeTab = tabId;
      this.currentPage = 1;

      if (!this.tabFilters[tabId]) {
        this.initializeFilters();
      }
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },

    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    exportAssetsToCSV() {
      let csvContent = "data:text/csv;charset=utf-8,";
      const headers = ["PC Name", "Location", "Domain", "User", "OS", "External IP", "Internal IP", "Last Reported"];
      csvContent += headers.join(",") + "\r\n";
      
      this.assets.forEach(asset => {
        const row = [
          asset.pc_name, asset.location, asset.domain, asset.user, 
          asset.os, asset.external_ip, asset.internal_ip, asset.last_reported
        ];
        csvContent += row.join(",") + "\r\n";
      });

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "network_assets.csv");
      document.body.appendChild(link);
      link.click();
    },
    async init() {
      try {
        this.$updateLoading(1);
        const response = await axios.get(`user/profile/${this.userId}`);
        if (response.data.companies && response.data.companies.length > 0) {
          this.companyId = response.data.companies[0].id;
          console.log('company id', this.companyId);
          this.fetchAssets();
        } else {
          console.error("No companies found for the user.");
        }
        this.$updateLoading(-1);
      } catch (error) {
        this.$updateLoading(-1);
        console.error('Error fetching user profile:', error);
      }
    },
  },
  mounted() {
    this.init();
    // this.debouncedFetchAssets = debounce(this.fetchAssets, 500);
  }
};
</script>

<style scoped>
th, td {
  text-align: left;
}
</style>
