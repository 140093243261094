<template>
  <div></div>
</template>

<script>
import Oidc from 'oidc-client';
import axios from 'axios';
import { useJwt } from '@vueuse/integrations/useJwt';

export default {
  name: 'AuthCallback',
  created() {
    const oidcClient = new Oidc.UserManager({ response_mode: "query" });
    oidcClient.signinRedirectCallback().then(user => {
      console.log('OIDC login successful', user);
      this.$updateLoading(1);
      // Send the OIDC token to your Laravel API in the Authorization header
      axios.post(axios.defaults.baseURL + 'auth/oidc', {}, { 
        headers: { 'Authorization': `Bearer ${user.access_token}` }
      }).then(response => {
        // Handle the response from your Laravel API
        console.log('Laravel token received', response.data);

        
        // Store the Laravel token in local storage
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('user_id', response.data.user_id);
        const { payload } = useJwt(response.data.token);
        const isInternal = payload.value.scopes && (payload.value.scopes.includes('internal') || payload.value.scopes.includes('extTech'));

        // Store the internal status
        localStorage.setItem('internal', isInternal);
        // localStorage.setItem('internal', response.data.internal);

        // Redirect to /dashboard
        const currentDomain = window.location.origin;
        const newPath = '/viewTickets';
        this.$updateLoading(-1);
        window.location.href = `${currentDomain}${newPath}`;
      }).catch(error => {
        console.error('Error processing user with Laravel API:', error);
        this.$updateLoading(-1);
        // Handle errors, e.g., show an error message or redirect to a login page
      });

    }).catch(error => {
      console.error('OIDC login error:', error);
      this.$updateLoading(-1);
      // Handle errors, e.g., show an error message or redirect to a login page
    });
  },
};
</script>
