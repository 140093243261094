<template>
  <Disclosure as="nav" class="bg-white dark:bg-gray-800 shadow">
    <div class="mx-auto w-full px-2 sm:px-4 lg:px-8">
      <div class="flex justify-between h-16">
        <div class="flex px-2 lg:px-0">
          <div class="flex-shrink-0 flex items-center">
            <img class="logo block lg:hidden" :src="getLogoPath" alt="Your Company">
            <img class="logo hidden lg:block" :src="getLogoPath" alt="Your Company">
          </div>
          <div class="hidden lg:ml-6 lg:flex lg:space-x-8">
            <!-- Dynamic Menu Items for Large Screens -->
            <!-- Assume menuItems is an array of objects { text: String, submenu: Array } -->
            <Menu as="div" class="relative mt-5" v-for="(item, index) in filteredMenuItems" :key="index" @click="navigateTo(item.text)">
              <MenuButton class="inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium leading-5 focus:outline-none transition duration-150 ease-in-out dark:text-gray-100" :class="item.current ? 'border-yellow-500 text-gray-900' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'">
              {{ item.text }} <span v-if="item.text == 'Tickets' && isIntUser" class="text-red-600">({{ undelegatedCount }})</span>
              <ChevronDownIcon class="ml-2 h-5 w-5" aria-hidden="true" v-if="item.submenu && item.submenu.length > 0" />
              </MenuButton>
              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95">
                <MenuItems class="origin-top-right absolute z-10 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-800">
                  <div v-for="submenuItem in item.submenu" :key="submenuItem" @click="navigateTo(submenuItem)">
                    <MenuItem>
                      <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-100 dark:hover:bg-gray-700">{{ submenuItem }}
                        <span v-if="submenuItem =='Delegate' && isIntUser" class="text-red-600">({{ undelegatedCount }})</span>
                      </a>
                    </MenuItem>
                  </div>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>

        <!-- <div class="flex-1 flex items-center justify-center px-2 lg:ml-6 lg:justify-end">
          <div class="max-w-lg w-full lg:max-w-xs">
            <label for="search" class="sr-only">Search</label>
            <div class="relative">
              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <MagnifyingGlassIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input id="search" name="search" class="block w-full pl-10 pr-3 py-2 border border-transparent leading-5 bg-gray-50 text-gray-900 placeholder-gray-500 focus:outline-none focus:bg-white focus:border-white focus:ring-white sm:text-sm" placeholder="Search" type="search">
            </div>
          </div>
        </div> -->
        <div class="flex lg:hidden">
          <!-- Mobile menu button -->
          <DisclosureButton class="p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-yellow-500">
            <span class="sr-only">Open main menu</span>
            <Bars3Icon class="h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>

        <div class="hidden lg:flex lg:items-center">
          <div class="flex items-center justify-center">
            <button
              @click="toggleDarkMode"
              class="flex items-center bg-gray-400 dark:bg-gray-400 p-0.5 rounded-full w-14 h-6 focus:outline-none"
            >
              <!-- Moon Icon -->
              <svg
                v-show="isDark"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="h-4 w-4 text-gray-500 dark:text-gray-100 ml-1"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M21 12.38A9 9 0 11 6.62 3 7 7 0 0021 12.38z"
                />
              </svg>

              <!-- Sun Icon -->
              <svg
                v-show="!isDark"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="h-4 w-4 text-yellow-500 ml-1"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 2v2m0 16v2m10-10h2M2 12h2m14.48 7.02l1.42 1.42M4.1 6.1l1.42-1.42m0 11.48l1.42 1.42M4.1 4.1l1.42 1.42m11.48 11.48l1.42 1.42M19.9 6.1l1.42-1.42M12 6a6 6 0 100 12 6 6 0 000-12z"
                />
              </svg>

              <!-- Toggle Circle -->
              <span
                :class="isDark ? 'translate-x-3' : 'translate-x-0'"
                class="inline-block bg-white rounded-full h-4 w-4 transform transition-transform ml-1"
              ></span>
            </button>

              </div>
          <!-- <button class="flex-shrink-0 p-1 border-2 border-transparent rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 focus:bg-gray-100" type="button">
            <BellIcon class="h-6 w-6 dark:text-gray-100" aria-hidden="true" />
          </button> -->
          <!-- Profile dropdown -->
          <Menu as="div" class="ml-4 relative flex-shrink-0">
            <div>
              <MenuButton class="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                <!-- <img class="h-8 w-8 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" /> -->
                <div 
                  class="h-8 w-8 rounded-full flex items-center justify-center 
                        bg-gray-400 border-gray-400 dark:bg-gray-600 dark:border-gray-600 text-white">
                  <svg class="h-6 w-6 text-white" 
                      xmlns="http://www.w3.org/2000/svg" 
                      fill="none" 
                      viewBox="0 0 24 24" 
                      stroke="currentColor">
                    <path stroke-linecap="round" 
                          stroke-linejoin="round" 
                          stroke-width="2" 
                          d="M5.121 17.804A4 4 0 018 16h8a4 4 0 012.879 1.804M12 14a4 4 0 100-8 4 4 0 000 8z" />
                  </svg>
                </div>
              </MenuButton>
            </div>
            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95">
              <MenuItems class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <MenuItem>
                  <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" @click="navigateTo('Profile')">Your Profile</a>
                </MenuItem>
                <!-- <MenuItem>
                  <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" @click="navigateTo('Settings')">System Settings</a>
                </MenuItem> -->
                <MenuItem>
                  <button @click="signOut" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left">Sign out</button>
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>
    </div>

    <!-- Mobile menu, show/hide based on menu state. -->
    <DisclosurePanel class="lg:hidden">
      <div class="px-2 pt-2 pb-3 space-y-1">
        <!-- Dynamically generated mobile menu items -->
        <a href="#" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">Dashboard</a>
        <!-- Repeat for each mobile menu item as needed -->
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ChevronDownIcon, MagnifyingGlassIcon, Bars3Icon, BellIcon } from '@heroicons/vue/20/solid'
import { useRouter } from 'vue-router';
import ThemeService from '@/services/ThemeService';
import { ref, onMounted, computed, onBeforeUnmount } from 'vue'
import { useJwt } from '@vueuse/integrations/useJwt';
import axios from 'axios';
import { useStore } from 'vuex';

const isDark = ref(false);

const router = useRouter();
const token = localStorage.getItem('token');
let isIntUser = ref(false);
let userrole = ref(0);
const undelegatedCount = ref(0);
let pollingInterval;

if (token) {
  try {
    const { payload } = useJwt(token);
    isIntUser.value = payload.value.scopes && (payload.value.scopes.includes('internal') || payload.value.scopes.includes('extTech'));
  } catch (error) {
    console.error('Invalid token:', error);
  }
}

const store = useStore();

const getLogoPath = computed(() => {
  return `/assets/logos/${store.state.logo || localStorage.getItem('logo')}`;
});

const fetchUnassignedTicketsCount = async () => {
  try {
    if (isIntUser.value) {
      const response = await axios.get(`${axios.defaults.baseURL}ticket/new-count`);
      const newCount = response.data;
      if (newCount !== undelegatedCount.value) {
        undelegatedCount.value = newCount;

        // Check if we're on the /delegator route
        if (router.currentRoute.value.name === 'delegator') {
          store.commit('triggerReloadDelegatorTickets');
        }
      }
    }
  } catch (error) {
    console.error('Error fetching unassigned tickets count:', error);
  }
};

const getUserRole = async () => {
  try {
    const roleResponse = await axios.get(`user/role/${localStorage.getItem('user_id')}`);
    userrole.value = roleResponse.data.role_id;
  } catch (error) {
    console.error('Error fetching user role:', error);
  }
};

const startPolling = () => {
  pollingInterval = setInterval(fetchUnassignedTicketsCount, 30000);
};

const stopPolling = () => {
  if (pollingInterval) {
    clearInterval(pollingInterval);
  }
};

const navigateTo = (menuItem) => {
  const routes = {
    'Delegate': 'delegator',
    'Create Ticket': 'newticket',
    'View Tickets': 'viewTickets',
    'Profile': isIntUser.value ? 'profile' : 'extprofile',
    'Settings': 'Settings',
    'View': 'viewClientTickets',
    'Assets': 'networkAssets'
  };
  if (isIntUser.value) {
    menuItems.forEach(item => {
      item.current = false;
      if (item.submenu.includes(menuItem)) {
        item.current = true;
      }
    });
  } else {
    extMenuItems.forEach(item => {
      item.current = false;
      if (item.submenu.includes(menuItem)) {
        item.current = true;
      }
    });
  }

  if (routes[menuItem]) {
    router.push({ name: routes[menuItem] });
  }
};

const filteredMenuItems = computed(() => {
  return isIntUser.value ? menuItems : extMenuItems.filter(item => item.roles.includes(userrole.value));
});

const menuItems = [
// {
//   text: 'Time',
//   current: true,
//   submenu: []
// },
{
  text: 'Tickets',
  current: true,
  submenu: ['Delegate', 'View Tickets', 'Create Ticket']
},
// {
//   text: 'Clients',
//   current: false,
//   submenu: ['Submenu option 1', 'Submenu option 2', 'Submenu option 3']
// },
// {
//   text: 'Contacts',
//   current: false,
//   submenu: ['Submenu option 1', 'Submenu option 2', 'Submenu option 3']
// },
// {
//   text: 'Management',
//   current: false,
//   submenu: ['Submenu option 1', 'Submenu option 2', 'Submenu option 3']
// },
// {
//   text: 'Accounting',
//   current: false,
//   submenu: ['Submenu option 1', 'Submenu option 2', 'Submenu option 3']
// }
];

const extMenuItems = [
  {
    text: 'Tickets',
    current: true,
    submenu: ['View', 'Create new ticket'],
    roles: [60, 70]
  },
  {
    text: 'Network Assets',
    current: false,
    submenu: ['Assets'],
    roles: [60]
  },
  {
    text: 'Emailing',
    current: false,
    submenu: ['Email Quarantine', '*New* Email Quarantine'],
    roles: [60]
  },
  {
    text: 'Documents',
    current: false,
    submenu: [],
    roles: [60]
  },
  {
    text: 'Make Payment',
    current: false,
    submenu: [],
    roles: [60]
  }
];

const signOut = () => {
  localStorage.removeItem('token'); // Remove the token from localStorage
  localStorage.removeItem('user_id'); // Remove the token from localStorage
  localStorage.removeItem('internal'); // Remove the token from localStorage
  router.push({ name: 'login' }); // Redirect to /login page
  window.location.reload();
};
const toggleDarkMode = () => {
  ThemeService.toggleDarkMode();
  isDark.value = !isDark.value;
  console.log('in toggle:' + isDark.value);
};
onMounted(() => {
  ThemeService.loadThemePreference(); // Load the theme when the component mounts
  isDark.value = ThemeService.getCurrentMode();
  getUserRole();
  fetchUnassignedTicketsCount(); // Fetch initially
  startPolling();
});
onBeforeUnmount(() => {
  stopPolling();
});

</script>
  
<style scoped>
.logo {
  width: 160px;
}
button svg {
transition: color 0.2s ease-in-out;
}
</style>
  