import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import ContactUsSuccess from '../views/ContactUsSuccess.vue'
import RestorePassword from '../views/SetPassword.vue'
import ContactUs from "@/views/ContactUs.vue";
import OidcCallback from '../views/OidcCallback.vue';
import Time from '../views/Time.vue';
import Delegator from '../views/Delegator.vue'; 
import Profile from '../views/Profile.vue'; 
import ExtProfile from '../views/External/Profile.vue'; 
import Settings from '../views/Settings.vue'; 
import NewTicket from '@/views/NewTicket.vue';
import TicketsSummary from '@/views/TicketsSummary.vue';
import EditTicket from '@/views/EditTicket.vue';
import TicketDetails from '@/views/TicketDetails.vue';
import ViewTickets from '@/views/External/ViewTickets.vue'
import NetworkAssets from '@/views/External/NetworkAssets.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    // {
    //   path: '/',
    //   name: 'Time',
    //   component: Time,
    //   meta: { requiresAuth: true }
    // },
    // {
    //   path: '/',
    //   redirect:  isInternal ? '/viewTickets' : '/'
    // },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: { requiresAuth: false }
    },
    {
      path: '/register',
      name: 'register',
      component: Register,
      meta: { requiresAuth: false }
    },
    {
      path: '/contactUs',
      name: 'contactUs',
      component: ContactUs,
      meta: { requiresAuth: false }
    },
    {
      path: '/contactUs-success',
      name: 'contactUsSuccess',
      component: ContactUsSuccess,
      meta: { requiresAuth: false }
    },
    {
      path: '/forgot',
      name: 'forgot',
      component: ForgotPassword,
      meta: { requiresAuth: false }
    },
    {
      path:'/password-reset',
      name: 'setPassword',
      component: RestorePassword,
      meta: { requiresAuth: false }
    },
    {
      path: '/callback',
      name: 'oidc-callback',
      component: OidcCallback,
      meta: { requiresAuth: false }
    },
    {
      path: '/delegator',
      name: 'delegator',
      component: Delegator,
      meta: { requiresAuth: true }
    },
    {
      path: '/newticket',
      name: 'newticket',
      component: NewTicket,
      meta: { requiresAuth: true }
    },
    {
      path: '/viewTickets',
      name: 'viewTickets',
      component: TicketsSummary,
      meta: { requiresAuth: true }
    },
    {
      path: '/viewClientTickets',
      name: 'viewClientTickets',
      component: ViewTickets,
      meta: { requiresAuth: true }
    },
    {
      path: '/editTicket/:id',
      name: 'editTicket',
      component: EditTicket,
      meta: { requiresAuth: true },
      props: true
    },
    {
      path: '/ticket/:ticketId',
      name: 'ticketDetails',
      component: TicketDetails,
      props: true
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
      meta: { requiresAuth: true }
    },
    {
      path: '/extprofile',
      name: 'extprofile',
      component: ExtProfile,
      meta: { requiresAuth: true }
    },
    {
      path: '/settings',
      name: 'Settings',
      component: Settings,
      meta: { requiresAuth: true }
    },
    {
      path: '/assests',
      name: 'networkAssets',
      component: NetworkAssets,
      meta: { requiresAuth: true }
    }
  ]
})

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('token')
  const isInternal = localStorage.getItem('internal') === 'true'

  if (to.path === '/') {
    if (isInternal) {
      return next('/viewTickets')
    } else {
      return next('/viewClientTickets')
    }
  }

  if (to.path === '/viewTickets') {
    if (!isInternal) {
      return next('/viewClientTickets')
    }
  }
  if (to.meta.requiresAuth && !loggedIn) {
    return next({
      path: '/login'
    })
  }
  next()
})

export default router