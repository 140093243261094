<template>
  <div class="max-w-full mx-auto my-5 border rounded-2xl px-6 py-6 dark:bg-gray-600 dark:border-gray-600">
    <div class="bg-gray-100 dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
      <!-- <div class="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
        <div class="w-full md:w-1/2">
          <form class="flex items-center">
            <label for="simple-search" class="sr-only">Search</label> 
            <div class="relative w-full">
              <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                </svg>
              </div>
              <input type="text" id="simple-search" v-model="filters.keywords" @input="debouncedFetchTickets" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Search" />
            </div>
            <button type="submit" class="hidden">Search</button>
          </form>
        </div>
        <div class="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
          <div class="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
            <button @click="exportTicketsToCSV" type="button" class="flex items-center justify-center text-gray-100 bg-gray-400 hover:bg-yellow-500 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800">
              <svg class="h-5 w-5 text-white mr-2" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8" />
                <polyline points="16 6 12 2 8 6" />
                <line x1="12" y1="2" x2="12" y2="15" />
              </svg>
              Export
            </button>
            </div>
        </div>
      </div> -->
      <div class="overflow-x-auto">
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
            <th scope="col" class="px-4 py-3 cursor-pointer">ID
            </th>
            <th scope="col" class="px-4 py-3">Details</th>
            <th scope="col" class="px-4 py-3 cursor-pointer">Submitted
            </th>
            <th scope="col" class="px-4 py-3 cursor-pointer">Priority
            </th>
            <th scope="col" class="px-4 py-3 cursor-pointer">Requested By
            </th>
            <th scope="col" class="px-4 py-3 cursor-pointer">Assigned To
            </th>
            <th scope="col" class="px-4 py-3 cursor-pointer">Last Activity/Hours/Days Old
            </th>
            <th scope="col" class="px-4 py-3">Actions</th>
          </tr>
          </thead>
          <tbody class="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
            <template v-if="ticket">
              <tr class="hover:bg-gray-100 dark:hover:bg-gray-700">
                <!-- <td class="px-4 py-2">
                  <button @click="toggleDetails(ticket.id)" class="focus:outline-none transition-transform duration-200">
                    <svg :class="{ 'rotate-180': isDetailsVisible(ticket.id) }" class="h-6 w-6 text-gray-500 dark:text-gray-400 transition-transform duration-200" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z"/>
                      <path d="M6 9l6 6 6-6H6z"/>
                    </svg>
                  </button>
                </td> -->
                <td class="px-4 py-2">
                  <span class="inline-flex items-center rounded-md text-gray-700 bg-gray-200 dark:bg-gray-100 px-2 py-1 text-xs font-medium ring-1 ring-inset ring-gray-500/10">{{ ticket.id }}</span>
                </td>
                <td class="px-4 py-2 font-medium text-gray-900 dark:text-white">
                  {{ ticket.redmineissueid != 0 ? ticket.redmineissueid : '' }}
                  <!-- {{ ticket.ticket_type ? ticket.ticket_type.description : 'Description' }}: -->
                  {{ isDetailsVisible(ticket.id) ? (ticket.description || 'N/A') : (ticket.desc_short || '') }}
                </td>
                <td class="px-4 py-2 whitespace-nowrap">{{ ticket.submitted }}</td>
                <td class="px-4 py-2 whitespace-nowrap">{{ ticket.pdate }} {{ ticket.ptime }}</td>
                <td class="px-4 py-2">{{ ticket.clientName }}</td>
                <td class="px-4 py-2">{{ ticket.assigned_to }}</td>
                <td class="px-4 py-2">
                  <div class="flex flex-row space-x-1">
                    <span :class="[
                            'inline-flex',
                            'items-center',
                            'rounded-md',
                            'px-2',
                            'py-1',
                            'text-xs',
                            'font-medium',
                            `text-${ticket.urgencycolor}-700`,
                            `bg-${ticket.urgencycolor}-50`,
                            `ring-${ticket.urgencycolor}-700/10`,
                            'ring-1',
                            'ring-inset',
                            `dark:bg-${ticket.urgencycolor}-50/20`,
                            `dark:ring-${ticket.urgencycolor}-400/75`,
                            `dark:text-${ticket.urgencycolor}-300`
                          ]">{{ ticket.lastjobdate ? ticket.lastjobdate : 'N/A' }}
                      </span>
                    <!-- <span :class="getUrgencyClass(ticket.urgencycolor)">
                      {{ ticket.lastjobdate ? ticket.lastjobdate : 'N/A' }}
                    </span> -->
                    <!-- <span class="inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10 dark:bg-purple-50/20 dark:ring-purple-400/75 dark:text-purple-300">{{ ticket.lastjobdate?ticket.lastjobdate:'N/A' }}</span> -->
                    <span class="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10 dark:bg-blue-50/20 dark:ring-blue-400/75 dark:text-blue-300">{{ ticket.logged_time?ticket.logged_time:'N/A' }}</span>
                    <span class="inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10 dark:bg-purple-50/20 dark:ring-purple-400/75 dark:text-purple-300">{{ ticket.daysold?ticket.daysold:'N/A' }}</span>
                  </div>
                </td>
                <td class="px-4 py-2">
                  <div class="flex items-center justify-left space-x-2">
                    <button @click="startJob(ticket.id)" title="Start Job">
                      <svg class="h-5 w-5 text-red-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <polygon points="10 8 16 12 10 16 10 8" /></svg>
                    </button>
                    <button @click="addJob(ticket.id)" title="Add Job">
                      <svg class="h-5 w-5 text-blue-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z"/>
                      </svg>
                    </button>
                    <!-- <button @click="toggleQuickNoteForm(ticket.id)" title="Add Note">
                      <svg class="h-5 w-5 text-green-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <line x1="13" y1="20" x2="20" y2="13" />  <path d="M13 20v-6a1 1 0 0 1 1 -1h6v-7a2 2 0 0 0 -2 -2h-12a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7" /></svg>
                    </button> -->
                    <button @click="openMergeTicketModal(ticket.id)" title="Merge ticket">
                      <svg class="h-5 w-5 text-cyan-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="7" cy="18" r="2" />  <circle cx="7" cy="6" r="2" />  <circle cx="17" cy="12" r="2" />  <line x1="7" y1="8" x2="7" y2="16" />  <path d="M7 8a4 4 0 0 0 4 4h4" /></svg>
                    </button>
                    <button title="Add Reminder" @click="openCreateReminderModal(ticket.id)">
                      <svg class="h-5 w-5 text-blue-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="12" cy="13" r="7" />  <polyline points="12 10 12 13 14 13" />  <line x1="7" y1="4" x2="4.25" y2="6" />  <line x1="17" y1="4" x2="19.75" y2="6" /></svg>
                    </button>
                    <button @click="editTicket(ticket.id)" title="Edit Ticket">
                      <svg class="h-5 w-5 text-yellow-500" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                        <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                        <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
                      </svg>
                    </button>
                    <button @click="deleteTicket(ticket.id)" title="Delete Ticket">
                      <svg class="h-5 w-5 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                      </svg>
                    </button>
                  </div>
                </td>
              </tr>
              <transition name="quicknote-transition">
                <tr v-if="isQuickNoteFormVisible(ticket.id)" :key="ticket.id + '-quicknote'" class="bg-gray-100 dark:bg-gray-700">
                  <td colspan="8" class="px-4 py-2">
                    <div class="p-4 rounded-md shadow-md bg-yellow-100 dark:bg-gray-900">
                      <div class="grid grid-cols-3 gap-4 mb-2">
                        <div>
                          <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">Time</label>
                          <input type="number" v-model="quickNoteTime" step="0.10" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-500 dark:border-gray-900 dark:text-gray-100" min="0">
                        </div>
                        <div>
                          <label class="block text-sm font-medium text-gray-700 dark:text-gray-100">IA</label>
                          <input type="number" v-model="quickNoteIA" step="0.10" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-500 dark:border-gray-900 dark:text-gray-100" min="0">
                        </div>
                        <div>
                          <label class="block text-sm font-medium text-gray-700 dark:text-gray-100 mr-2">Publish</label>
                          <input type="checkbox" v-model="quickNotePublish" class="rounded-md border-gray-300 shadow-sm dark:bg-gray-400 dark:border-gray-900 dark:text-gray-100">
                        </div>
                      </div>
                      <textarea v-model="quickNoteText" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-500 dark:border-gray-900 dark:text-gray-100" rows="4"></textarea>
                      <div class="mt-4 flex justify-end space-x-2">
                        <button @click="saveQuickNote(ticket.id)" class="bg-yellow-500 text-white px-4 py-2 rounded-md hover:bg-yellow-400">Save</button>
                        <button @click="toggleQuickNoteForm(ticket.id)" class="bg-gray-400 text-white px-4 py-2 rounded-md hover:bg-gray-600 dark:bg-red-700 dark:hover:bg-red-500">Cancel</button>
                      </div>
                    </div>
                  </td>
                </tr>
              </transition>
              <transition name="details-transition">
                <tr v-if="isDetailsVisible(ticket.id)" :key="ticket.id + '-details'" class="bg-gray-100 dark:bg-gray-700">
                  <td colspan="8" class="px-4 py-2">
                    <div class="p-4 rounded-md shadow-md bg-gray-50 dark:bg-gray-900">
                      <p v-if="ticket.delegatornotes" class="mb-2"><strong>AIM Instruction-Notes:</strong> {{ ticket.delegatornotes }}</p>
                      <p v-if="ticket.privateinfo" class="mb-2"><strong>Private Notes:</strong> {{ ticket.privateinfo }}</p>
                      <!-- Timeline for ticket notes -->
                      <ol class="relative border-l border-gray-200 dark:border-gray-700">
                        <li v-for="note in ticket.notes" :key="note.id" class="mb-10 ml-6">
                          <div v-if="note">
                            <span class="absolute flex items-center justify-center w-8 h-8 bg-blue-100 rounded-full -left-4 ring-4 ring-white dark:ring-gray-900 dark:bg-blue-900">
                              <svg aria-hidden="true" class="w-3 h-3 text-blue-800 dark:text-blue-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"/>
                              </svg>
                            </span>
                            <div class="flex justify-between items-center">
                              <time class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">{{ note.ts }}</time>
                            </div>
                            <h3 class="text-lg font-semibold text-gray-900 dark:text-white" v-if="note.user_profile">{{ note.user_profile.firstname }} {{ note.user_profile.lastname }}</h3>
                            <p class="text-base font-normal text-gray-500 dark:text-gray-400">{{ note.description }}</p>
                            <p class="mb-4 text-base text-xs text-gray-500 dark:text-gray-400">Total Time: {{ note.totaltime }}</p>
                          </div>
                        </li>
                      </ol>
                    </div>
                  </td>
                </tr>
              </transition>
            </template>
          </tbody>
        </table>
        <MergeTicketsModal :isVisible="mergeTicketModal" :sourceTicketId="selectedTicketId" @close="mergeTicketModal = false" @complete="handleMergeTickets" />
        <CreateReminderModal :isVisible="createReminderModal" :sourceTicketId="selectedTicketId" :techs="allTech" :currentTech="selectedTech" @close="createReminderModal = false" @complete="handleCreateReminder" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import MergeTicketsModal from "@/views/modals/MergeTicketsModal.vue";
import CreateReminderModal from "@/views/modals/CreateReminderModal.vue";

export default {
  components: {
    MergeTicketsModal,
    CreateReminderModal
  },
  props: {
    ticketId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      filters: {
        ticket_id: this.ticketId
      },
      userId: localStorage.getItem('user_id'),
      ticket: null,
      expandedTicketId: this.ticketId,
      quickNoteFormVisible: {},
      quickNoteText: '',
      quickNoteTime: 0,
      quickNoteIA: 0,
      quickNotePublish: false,
      createReminderModal: false,
      mergeTicketModal: false,
      selectedTicketId: 0,
    };
  },
  mounted() {
    this.fetchTicketDetails();
  },
  methods: {
    fetchTicketDetails() {
      this.$updateLoading(1);
      const params = {
        page: this.page,
        itemsPerPage: 1,
        sort_column: 'priority',
        sort_order: 'DESC',
        ...this.filters
      };

      try {
        // Your fetch logic here
        axios.post('ticket/getAll', params)
        .then(response => {
          this.ticket = response.data.data[0];
          console.log('tickets: ', this.ticket);
          this.expandedTicketId = this.ticketId;
          this.toggleDetails(this.ticket.id);
          this.toggleQuickNoteForm(this.ticket.id);
          // console.log('totalTickets: ', this.tickets);
        })
        .catch(error => {
          console.error(error);
        }).finally(() => {
          this.$updateLoading(-1);
        });
      } catch (error) {
        console.error('Error fetching tickets:', error);
      }
    },
    toggleDetails(ticketId) {
      this.expandedTicketId = this.expandedTicketId === ticketId ? null : ticketId;
    },
    isDetailsVisible(ticketId) {
      return this.expandedTicketId === ticketId;
    },
    startJob(ticketId) {
      window.open(`https://atms.aimitservices.com/jobList.php?func=quick_add_job_from_ticket&ticket_id=${ticketId}`, '_blank');
    },
    addJob(ticketId) {
      window.open(`https://atms.aimitservices.com/addEdit.php?ticketID=${ticketId}&return_to=tickets-summary-IMPROVED.php`, '_blank');
    },
    toggleQuickNoteForm(ticketId) {
      this.quickNoteText= '';
      this.quickNoteTime= 0;
      this.quickNoteIA= 0;
      this.quickNoteFormVisible = {
        ...this.quickNoteFormVisible,
        [ticketId]: !this.quickNoteFormVisible[ticketId]
      };
      console.log(this.quickNoteFormVisible);
      console.log(this.isQuickNoteFormVisible(ticketId));
    },
    isQuickNoteFormVisible(ticketId) {
      return this.quickNoteFormVisible[ticketId] || false;
    },
    openMergeTicketModal(ticketId) {
      this.selectedTicketId = ticketId;
      this.mergeTicketModal = true;
    },
    openCreateReminderModal(ticketId) {
      this.selectedTicketId = ticketId;
      this.createReminderModal = true;
    },
    editTicket(id) {
      this.$router.push({ name: 'editTicket', params: { id: id } });
    },
    async deleteTicket(ticketId) {
        const ticket = this.tickets.find(t => t.id === ticketId);
        if (!ticket) {
            alert("Ticket not found.");
            return;
        }

        // Prevent deletion if worked time exists
        if (ticket.logged_time > 0) {
            alert(`Cannot delete. This ticket has ${ticket.logged_time} hours of worked time.`);
            return;
        }

        const tmp = confirm(`Are you sure you would like to delete ticket #${ticket.id}?`);
        if (!tmp) {
            return;
        }

        this.$updateLoading(1);
        try {
            const response = await axios.post('ticket/delete', { delete: ticketId });
            alert(response.data.message);
            this.fetchTickets(); // Refresh the tickets list
        } catch (error) {
            console.error('Error deleting ticket:', error);
            alert(error.response.data.message || 'Error deleting ticket');
        } finally {
            this.$updateLoading(-1);
        }
    },
  }
};
</script>

<style scoped>
.details-transition-enter-active, .details-transition-leave-active {
  transition: all 0.1s ease;
}
.details-transition-enter, .details-transition-leave-to /* .details-transition-leave-active in <2.1.8 */ {
  opacity: 0;
  transform: translateY(-10px);
}

.quicknote-transition-enter-active, .quicknote-transition-leave-active {
  transition: all 0.1s ease;
}
.quicknote-transition-enter, .quicknote-transition-leave-to /* .quicknote-transition-leave-active in <2.1.8 */ {
  opacity: 0;
  transform: translateY(-10px);
}

.rotate-180 {
  transform: rotate(180deg);
}

.transition-transform {
  transition: transform 0.1s ease;
}
</style>